"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _productMenu = _interopRequireDefault(require("./../components/product-menu.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    productMenu: _productMenu.default
  },
  data: function data() {
    return {
      mySwiper: null
    };
  },
  destroyed: function destroyed() {},
  mounted: function mounted() {
    this.mySwiper = new Swiper('.swiper-container', {
      autoplay: true,
      loop: false,
      speed: 20000,
      //匀速时间
      freeMode: true //设置为true则变为free模式
    });
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    siteConfig: function siteConfig(state) {
      return state.app.siteConfig;
    }
  })),
  methods: {
    goUrl: function goUrl(item) {
      if (item.url) {
        window.open(item.url);
      }
    },
    goUrlPath: function goUrlPath(url) {
      this.$router.push({
        path: url
      });
    },
    //鼠标移入悬停
    swiperMouseenterContainer: function swiperMouseenterContainer() {
      if (this.mySwiper) {
        this.mySwiper.stopAutoplay();
      }
    },
    //鼠标移出滚动
    swiperMouseleaveContainer: function swiperMouseleaveContainer() {
      if (this.mySwiper) {
        this.mySwiper.startAutoplay();
      }
    }
  }
};
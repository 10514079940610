"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    menuIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    goToPages: function goToPages(url, index) {
      if (index == this.menuIndex) {
        return false;
      }
      this.$router.push({
        path: url
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _map = require("@/utils/map");
var _comm_header = _interopRequireDefault(require("./../components/comm_header.vue"));
var _comm_footer = _interopRequireDefault(require("./../components/comm_footer.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Index",
  components: {
    comHeader: _comm_header.default,
    comFooter: _comm_footer.default
  },
  data: function data() {
    return {
      configData: {
        borderBorder: ['#095A3E', '#27F38E'],
        digConfig1: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig2: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig3: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig4: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig5: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig6: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        chartOption1: {
          title: {
            text: '',
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '10%',
            top: '20%',
            data: [{
              name: '种植',
              color: "#41E4BB"
            }, {
              name: '养殖',
              color: "#E44141"
            }, {
              name: '数智赋能',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '种植',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '养殖',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '数智赋能',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }],
          color: ['#41E4BB', '#E44141', '#0091FF']
        },
        chartOption2: {
          radius: '80%',
          activeRadius: '85%',
          digitalFlopStyle: {
            fontSize: 10,
            fill: '#fff'
          },
          data: [{
            name: '第一产业',
            value: 55
          }, {
            name: '第二产业',
            value: 120
          }, {
            name: '第三产业',
            value: 78
          }, {
            name: '产业总值',
            value: 66
          }, {
            name: '产业总量',
            value: 80
          }],
          color: ['#27F38E', '#59FFFF', '#FF386B', '#FFFFFF', '#F5E74F'],
          showOriginValue: true,
          lineWidth: 10
        },
        chartOption3: {
          title: {
            text: '产权交易项目分析',
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '2%',
            top: '16%',
            data: [{
              name: '需求项目',
              color: "#41E4BB"
            }, {
              name: '供应项目',
              color: "#E44141"
            }, {
              name: '成交项目',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '需求项目',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '供应项目',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '成交项目',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }],
          color: ['#41E4BB', '#E44141', '#0091FF']
        },
        chartOption4: {
          title: {
            text: '当日数据接口调用次数',
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '10%',
            top: '20%',
            data: [{
              name: '设备接入',
              color: "#41E4BB"
            }, {
              name: '数据共享',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '22:00'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '设备接入',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar'
          }, {
            name: '数据共享',
            data: [1800, 5822, 3500, 1100, 2000, 2000, 4500],
            type: 'bar'
          }],
          color: ['#41E4BB', '#0091FF']
        },
        chartOption5: {
          data: [{
            name: '家庭农场',
            value: 55
          }, {
            name: '种养大户',
            value: 120
          }, {
            name: ' 合 作 社  ',
            value: 71
          }, {
            name: '龙头企业',
            value: 66
          }],
          showValue: true,
          columnColor: 'rgba(39, 243, 142, 0.4)'
        },
        chartOption6: {
          title: {
            text: ''
          },
          xAxis: {
            name: '',
            data: ['物质文化', '制度文化', '心里文化'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            data: [2339, 1899, 2118],
            type: 'bar',
            backgroundBar: {
              show: true
            },
            gradient: {
              color: ['rgba(39, 243, 142, 0.8)', 'rgba(39, 243, 142, 0.1)'],
              radius: '53%'
            }
          }]
        },
        chartOption7: {
          title: {
            text: ''
          },
          xAxis: {
            name: '',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            data: [2339, 1899, 2118, 1790, 3265, 4465, 3996],
            type: 'bar',
            barStyle: {
              barBorderRadius: [50, 50, 0, 0]
            },
            gradient: {
              color: ['rgba(39, 243, 142, 0.8)', 'rgba(39, 243, 142, 0.1)'],
              radius: '53%'
            }
          }]
        },
        chartOption8: {
          title: {
            text: ''
          },
          legend: {
            right: '10%',
            top: '10%',
            data: [{
              name: '煤炭产量（吨）',
              color: "#27F38E"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          xAxis: {
            name: '',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '煤炭产量（吨）',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            lineStyle: {
              stroke: 'rgba(39, 243, 142, 1)'
            },
            smooth: true,
            linePoint: {
              style: {
                stroke: 'rgba(39, 243, 142, 1)'
              }
            },
            lineArea: {
              show: true,
              smooth: true,
              gradient: ['rgba(39, 243, 142, 0.6)', 'rgba(39, 243, 142, 0)']
            }
          }]
        }
      },
      mapInstance: null,
      isMap: false,
      AMap: null,
      isLoading: false
    };
  },
  created: function created() {
    //加载地图
    this.getMapEventData();
  },
  methods: {
    initDigConfig: function initDigConfig() {
      var _this = this;
      setTimeout(function () {
        _this.configData.digConfig1 = {
          number: [999],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig2 = {
          number: [7149],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig3 = {
          number: [48416],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig4 = {
          number: [16579],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig5 = {
          number: [51546],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig6 = {
          number: [23344],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
      }, 400);
    },
    getMapEventData: function getMapEventData() {
      var _this2 = this;
      if (this.isMap == false) {
        (0, _map.loadMap)(function (AMap) {
          if (window.mapInstance) {
            _this2.destroyMap(window.mapInstance);
          }
          _this2.mapInstance = new AMap.Map("liy-map-car-container", {
            mapStyle: 'amap://styles/blue',
            //设置地图的显示样式
            center: [117.214, 34.9268],
            zoom: 16
            // layers: [new AMap.TileLayer.Satellite()],
          });
          window.mapInstance = _this2.mapInstance;
          _this2.isMap = true;
          _this2.AMap = AMap;
          // 地图加载完成
          _this2.mapInstance.on('complete', function (res) {
            _this2.mapInstanceComplete();
          });
        });
      }
    },
    mapInstanceComplete: function mapInstanceComplete() {
      var _this3 = this;
      setTimeout(function () {
        _this3.isLoading = true;
        //加载翻牌器
        _this3.initDigConfig();
      }, 400);
    },
    goToPages: function goToPages(url) {
      this.$router.push({
        path: url
      });
    },
    destroyMap: function destroyMap(map) {
      if (map) {
        map.destroy();
        window.mapInstance = null;
        window.mapInstanceWWE = null;
      }
    }
  }
};
"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _map = require("@/utils/map");
var _comm_header = _interopRequireDefault(require("./../components/comm_header.vue"));
var _comm_footer = _interopRequireDefault(require("./../components/comm_footer.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Index",
  components: {
    comHeader: _comm_header.default,
    comFooter: _comm_footer.default
  },
  data: function data() {
    return {
      configData: {
        borderBorder: ['#095A3E', '#27F38E'],
        chartOption1: {
          title: {
            text: '三务管理数据变化图',
            show: false,
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '10%',
            top: '20%',
            data: [{
              name: '数据一',
              color: "#41E4BB"
            }, {
              name: '数据二',
              color: "#E44141"
            }, {
              name: '数据三',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '村务',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '党务',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '财务',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }],
          color: ['#41E4BB', '#E44141', '#0091FF']
        },
        chartOption2: {
          radius: '80%',
          activeRadius: '85%',
          digitalFlopStyle: {
            fontSize: 10,
            fill: '#fff'
          },
          data: [{
            name: '28岁以下',
            value: 2651
          }, {
            name: '29-35岁',
            value: 1654
          }, {
            name: '36-50岁',
            value: 965
          }, {
            name: '51-60岁',
            value: 214
          }, {
            name: '61岁及以上',
            value: 127
          }],
          color: ['#27F38E', '#59FFFF', '#FF386B', '#FFFFFF', '#F5E74F'],
          showOriginValue: true,
          lineWidth: 10
        },
        chartOption3: {
          title: {
            text: '周销售额趋势',
            show: false
          },
          legend: {
            data: ['外地游客', '本地游客'],
            show: true,
            right: '10%',
            top: '20%',
            color: '#fff',
            style: {
              color: '#fff'
            },
            textStyle: {
              fill: '#fff'
            }
          },
          xAxis: {
            data: ['00:00', '04:00', '8:00', '12:00', '16:00', '20:00'],
            textStyle: {
              color: '#fff'
            },
            axisLabel: {
              style: {
                fill: '#E6F7FF'
              }
            },
            nameTextStyle: {
              fill: '#fff'
            }
          },
          yAxis: {
            name: '游客数量',
            data: 'value',
            nameTextStyle: {
              fill: '#E6F7FF'
            }
          },
          series: [{
            name: '外地游客',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar',
            textStyle: {
              color: '#fff'
            },
            color: '#27F38E'
            // shapeType: 'leftEchelon'
          }, {
            name: '本地游客',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar',
            color: '#FFB101'
            // shapeType: 'rightEchelon'
          }]
        },
        chartOption4: {
          title: {
            text: '三务管理数据变化图',
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '10%',
            top: '20%',
            data: [{
              name: '设备接入',
              color: "#41E4BB"
            }, {
              name: '数据共享',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '22:00'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '设备接入',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar'
          }, {
            name: '数据共享',
            data: [1800, 5822, 3500, 1100, 2000, 2000, 4500],
            type: 'bar'
          }],
          color: ['#41E4BB', '#0091FF']
        },
        chartOption5: {
          data: [{
            name: '家庭农场',
            value: 55
          }, {
            name: '种养大户',
            value: 120
          }, {
            name: ' 合 作 社  ',
            value: 71
          }, {
            name: '龙头企业',
            value: 66
          }],
          showValue: true,
          columnColor: 'rgba(39, 243, 142, 0.4)'
        },
        chartOption: {
          title: {
            text: '周销售额趋势',
            style: {
              fill: '#fff'
            }
          },
          xAxis: {
            name: '',
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '销售额',
            data: 'value',
            nameTextStyle: {
              fill: '#fff'
            },
            axisLine: {
              style: {
                stroke: '#fff'
              }
            },
            axisTick: {
              style: {
                stroke: '#fff'
              }
            },
            axisLabel: {
              style: {
                fill: '#fff',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#fff'
              }
            }
          },
          series: [{
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar',
            gradient: {
              color: ['#37a2da', '#67e0e3']
            }
          }]
        }
      },
      mapInstance: null,
      isMap: false,
      AMap: null
    };
  },
  created: function created() {
    this.getMapEventData();
  },
  methods: {
    getMapEventData: function getMapEventData() {
      var _this = this;
      if (this.isMap == false) {
        (0, _map.loadMap)(function (AMap) {
          if (window.mapInstance) {
            _this.destroyMap(window.mapInstance);
          }
          _this.mapInstance = new AMap.Map("liy-map-car-container", {
            mapStyle: 'amap://styles/blue',
            //设置地图的显示样式
            center: [117.214, 34.9268],
            zoom: 16,
            features: ['bg', 'road'],
            resizeEnable: true
          });
          window.mapInstance = _this.mapInstance;
          _this.isMap = true;
          _this.AMap = AMap;
          _this.createBundleIconMarkers();
          // 地图加载完成
          _this.mapInstance.on('complete', function (res) {});
        });
      } else {}
    },
    goToPages: function goToPages(url) {
      this.$router.push({
        path: url
      });
    },
    destroyMap: function destroyMap(map) {
      if (map) {
        map.destroy();
        window.mapInstance = null;
        window.mapInstanceWWE = null;
      }
    },
    //加载所有的地图标点
    createBundleIconMarkers: function createBundleIconMarkers() {
      var mar1 = this.addIconByMarker('/static/index_images/liy-img64.png', 117.214, 34.9268);
      var mar2 = this.addIconByMarker('/static/index_images/liy-img65.png', 117.200173, 34.885193);
      var mar3 = this.addIconByMarker('/static/index_images/liy-img66.png', 117.17628, 34.958086);
      var mar4 = this.addIconByMarker('/static/index_images/liy-img67.png', 117.063043, 34.876185);

      // 将 markers 添加到地图
      this.mapInstance.add([mar1, mar2, mar3, mar4]);
    },
    //加载标点
    addIconByMarker: function addIconByMarker(url, lng, lat) {
      // 创建一个 Icon
      var startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(100, 100),
        // 图标的取图地址
        image: url,
        // 图标所用图片大小
        imageSize: new AMap.Size(100, 100),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(0, 0)
      });

      // 将 icon 传入 marker
      return new AMap.Marker({
        position: new AMap.LngLat(lng, lat),
        icon: startIcon,
        offset: new AMap.Pixel(-13, -30)
      });
    }
  }
};
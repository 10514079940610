"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _data = require("@/api/data");
var _productMenu = _interopRequireDefault(require("../components/product-menu.vue"));
var _viewerjs = _interopRequireDefault(require("viewerjs"));
var _request = _interopRequireDefault(require("@/utils/request"));
require("viewerjs/dist/viewer.css");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    productMenu: _productMenu.default
  },
  data: function data() {
    return {
      listImage: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    siteConfig: function siteConfig(state) {
      return state.app.siteConfig;
    }
  })),
  created: function created() {
    var _this = this;
    (0, _request.default)({
      url: "index/listAboutImage",
      method: "get"
    }).then(function (res) {
      _this.listImage = res.data;
      _this.$nextTick(function () {
        var viewer = new _viewerjs.default(document.getElementById("list-image"), {
          transition: false
        });
      });
    });
  },
  destroyed: function destroyed() {},
  mounted: function mounted() {},
  methods: {}
};
"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/builds/lc-show-project/lc-online-showroom/node_modules/core-js/modules/es6.array.iterator.js");
require("/builds/lc-show-project/lc-online-showroom/node_modules/core-js/modules/es6.promise.js");
require("/builds/lc-show-project/lc-online-showroom/node_modules/core-js/modules/es6.object.assign.js");
require("/builds/lc-show-project/lc-online-showroom/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
require("@/styles/index.scss");
require("swiper/swiper.min.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _dataView = _interopRequireDefault(require("@jiaminghi/data-view"));
require("@/icons");
require("@/permission");
var _vueSeamlessScroll = _interopRequireDefault(require("vue-seamless-scroll"));
var _vuescroll = _interopRequireDefault(require("vuescroll"));
// A modern alternative to CSS resets

// lang i18n

// global css

// 将自动注册所有组件为全局组件

// icon
// permission control

_vue.default.use(_vueSeamlessScroll.default);
// 在这里设置全局配置
_vue.default.use(_vuescroll.default, {
  ops: {
    mode: 'native',
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: true,
      //是否只有滚动的时候才显示滚动条
      keepShow: true,
      // background: '#1AB063',
      opacity: 0.2,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: '1px',
      size: '1px',
      // disable: 'none',
      display: 'none',
      'overflow-y': 'hidden'
    }
  },
  // 在这里设置全局默认配置
  name: 'vueScroll' // 在这里自定义组件名字，默认是vueScroll
});

// set ElementUI lang to EN
_vue.default.use(_elementUi.default, {
  locale: _en.default
});
_vue.default.use(_dataView.default);
_vue.default.prototype.req = _request.default;
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});
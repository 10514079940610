var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "center-body",
      style:
        "background-image: url(" + _vm.siteConfig.showroom_background + ")",
    },
    [
      _c("product-menu", { attrs: { name: "news" } }),
      _vm._v(" "),
      _c("div", { staticClass: "center-main" }, [
        _c(
          "div",
          {
            staticClass: "swiper-container",
            on: {
              mouseenter: _vm.swiperMouseenterContainer,
              mouseleave: _vm.swiperMouseleaveContainer,
            },
          },
          [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(6, function (index1, item1) {
                return _c("div", { key: index1, staticClass: "swiper-slide" }, [
                  _c(
                    "div",
                    { staticClass: "expert-warp" },
                    _vm._l(5, function (index1, item1) {
                      return _c(
                        "dv-border-box-7",
                        { key: index1, staticClass: "expert-item" },
                        [
                          _c("div", { staticClass: "e-warp" }, [
                            _c("div", { staticClass: "expert-header" }, [
                              _c("img", {
                                staticClass: "e-img",
                                attrs: {
                                  src: "https://www.wchscu.cn/Uploads/Picture/2021/03/26/s605d85789bdd2.png",
                                  alt: "",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "expert-conetnt" }, [
                              _c("div", { staticClass: "e-name" }, [
                                _vm._v("刘继彦" + _vm._s(index1)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "e-desc" }, [
                                _vm._v(
                                  "\n                                        四川四川大学华西医院肿瘤生物治疗科副主任，华西医院资阳医院肿瘤科学科主任，医学博士，主任医师，博士生导师，一级专家科室：生物治疗科大学华西医院肿瘤生物治疗科副主任，华西医院资阳医院肿瘤科学科主任，医学博士，主任医师，博士生导师，一级专家科室：生物治疗科四川大学华西医院肿瘤生物治疗科副主任，华西医院资阳医院肿瘤科学科主任，医学博士，主任医师，博士生导师，一级专家科室：生物治疗科\n                                        四川四川大学华西医院肿瘤生物治疗科副主任，华西医院资阳医院肿瘤科学科主任，医学博士，主任医师，博士生导师，一级专家科室：生物治疗科大学华西医院肿瘤生物治疗科副主任，华西医院资阳医院肿瘤科学科主任，医学博士，主任医师，博士生导师，一级专家科室：生物治疗科四川大学华西医院肿瘤生物治疗科副主任，华西医院资阳医院肿瘤科学科主任，医学博士，主任医师，博士生导师，一级专家科室：生物治疗科\n                                    "
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ])
              }),
              0
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "liy-contaniner" },
    [
      _vm.isLoading == false
        ? _c(
            "dv-full-screen-container",
            { staticClass: "liy-loading" },
            [
              _c("dv-loading", { staticStyle: { color: "#fff" } }, [
                _vm._v("加载中..."),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("com-header"),
          _vm._v(" "),
          _c("div", { staticClass: "liy-map-container" }, [
            _c("div", {
              staticClass: "map-container",
              attrs: { id: "liy-map-car-container" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "liy-content" }, [
              _c("div", { staticClass: "liy-content-left" }, [
                _c(
                  "div",
                  { staticClass: "lc-left-top " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c("div", { staticClass: "liy-common-title" }, [
                            _c("div", { staticClass: "lct-left" }, [
                              _vm._v("官桥概括"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lct-right" }, [
                              _c("img", {
                                staticClass: "lct-right-btn1 zoom",
                                attrs: {
                                  src: require("@/assets/data_images/liy-img02.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogTableVisible1 = true
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "lct-right-btn2 zoom",
                                attrs: {
                                  src: require("@/assets/data_images/liy-img03.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogTableVisible = true
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-area" }, [
                            _c("div", { staticClass: "lcl-area-item" }, [
                              _c("div", { staticClass: "lcla-content" }, [
                                _c("div", { staticClass: "lcla-title" }, [
                                  _vm._v("行政面积"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "lcla-price" }, [
                                  _c("div", { staticClass: "text-green" }, [
                                    _vm._v("63.39"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-unit" }, [
                                    _vm._v("k㎡"),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-area-item" }, [
                              _c("div", { staticClass: "lcla-content" }, [
                                _c("div", { staticClass: "lcla-title" }, [
                                  _vm._v("文化景区"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "lcla-price" }, [
                                  _c("div", { staticClass: "text-green" }, [
                                    _vm._v("22"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-unit" }, [
                                    _vm._v("个"),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-content" }, [
                            _vm._v(
                              "\n                  滕州市官桥镇位于山东省枣庄市滕州市南部，北依齐鲁孔孟圣地，南邻苏淮平原，东边沂蒙山余脉，西接微山湖畔。驻地距城区17.5公里，东与羊庄镇交界，南濒薛河与柴胡店镇相连，西与张汪、鲍沟二镇接壤，北与木石、南沙河二镇毗邻。\n                "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-left-center " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c("div", { staticClass: "liy-common-title" }, [
                            _c("div", { staticClass: "lct-left" }, [
                              _vm._v("文明党建"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lct-right " }, [
                              _c(
                                "div",
                                {
                                  staticClass: "lct-right-more zoom",
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogTableVisible1 = true
                                    },
                                  },
                                },
                                [
                                  _c("span", [_vm._v("更多")]),
                                  _vm._v(" "),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/data_images/liy-img09.png"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lclc-civi" }, [
                            _c("div", { staticClass: "lclc-civi-item" }, [
                              _c("img", {
                                staticClass: "lclc-civi-img zoom",
                                attrs: {
                                  src: require("@/assets/data_images/liy-img21.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lclc-civi-div" }, [
                                _c("div", { staticClass: "lclc-civi-text1" }, [
                                  _vm._v("1018人"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "lclc-civi-text2" }, [
                                  _vm._v("村内党员"),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lclc-civi-item " }, [
                              _c("img", {
                                staticClass: "lclc-civi-img zoom",
                                attrs: {
                                  src: require("@/assets/data_images/liy-img20.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lclc-civi-div" }, [
                                _c("div", { staticClass: "lclc-civi-text1" }, [
                                  _vm._v("104人"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "lclc-civi-text2" }, [
                                  _vm._v("村委会党员"),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lclc-civi-item " }, [
                              _c("img", {
                                staticClass: "lclc-civi-img zoom",
                                attrs: {
                                  src: require("@/assets/data_images/liy-img21.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lclc-civi-div" }, [
                                _c("div", { staticClass: "lclc-civi-text1" }, [
                                  _vm._v("54"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "lclc-civi-text2" }, [
                                  _vm._v("村委会支部"),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lclc-content" }, [
                            _c("div", { staticClass: "lclc-content-title" }, [
                              _vm._v("9月18日党支部会议须知"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lclc-content-desc" }, [
                              _vm._v(
                                "\n                    2023年9月18日上午，化州市副市长杨亚田同志一行深入官桥镇各幼儿园实地查看，督导校园食品安全工作，官桥镇党委副书记、镇长庄志雄等同志陪同。\n                  "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-left-bottom" },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c("div", { staticClass: "liy-common-title" }, [
                            _c("div", { staticClass: "lct-left" }, [
                              _vm._v("乡村治理"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "200px" },
                                attrs: { option: _vm.configData.chartOption1 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "liy-content-center" }, [
                _c("div", { staticClass: "lc-center-top" }, [
                  _c("div", { staticClass: "liy-chart7" }, [
                    _c("div", { staticClass: "lc7-list" }, [
                      _c("div", { staticClass: "lc7-item" }, [
                        _c(
                          "div",
                          { staticClass: "lc7-text1" },
                          [
                            _c("dv-digital-flop", {
                              staticStyle: { height: "46px" },
                              attrs: { config: _vm.configData.digConfig1 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "lc7-text2" }, [
                          _vm._v("社区数量"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "lc7-item" }, [
                        _c(
                          "div",
                          { staticClass: "lc7-text1" },
                          [
                            _c("dv-digital-flop", {
                              staticStyle: { height: "46px" },
                              attrs: { config: _vm.configData.digConfig2 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "lc7-text2" }, [
                          _vm._v("全镇总人数(人)"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "lc7-item" }, [
                        _c(
                          "div",
                          { staticClass: "lc7-text1" },
                          [
                            _c("dv-digital-flop", {
                              staticStyle: { height: "46px" },
                              attrs: { config: _vm.configData.digConfig3 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "lc7-text2" }, [
                          _vm._v("全镇耕种面积(亩)"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "lc7-item" }, [
                        _c(
                          "div",
                          { staticClass: "lc7-text1" },
                          [
                            _c("dv-digital-flop", {
                              staticStyle: { height: "46px" },
                              attrs: { config: _vm.configData.digConfig4 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "lc7-text2" }, [
                          _vm._v("全年总收入(万元)"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "lc7-item" }, [
                        _c(
                          "div",
                          { staticClass: "lc7-text1" },
                          [
                            _c("dv-digital-flop", {
                              staticStyle: { height: "46px" },
                              attrs: { config: _vm.configData.digConfig5 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "lc7-text2" }, [
                          _vm._v("全年总支出(万元)"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "lc7-item" }, [
                        _c(
                          "div",
                          { staticClass: "lc7-text1" },
                          [
                            _c("dv-digital-flop", {
                              staticStyle: { height: "46px" },
                              attrs: { config: _vm.configData.digConfig6 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "lc7-text2" }, [
                          _vm._v("全镇总资产(亿元)"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "lc-center-center" }),
                _vm._v(" "),
                _c("div", { staticClass: "lc-center-bottom" }, [
                  _c(
                    "div",
                    { staticClass: "lccb-left" },
                    [
                      _c(
                        "dv-border-box-8",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            color: _vm.configData.borderBorder,
                            reverse: true,
                          },
                        },
                        [
                          _c("div", { staticClass: "lct-warp-v1" }, [
                            _c("div", { staticClass: "liy-common-title" }, [
                              _c("div", { staticClass: "lct-left" }, [
                                _vm._v("数字底座"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "liy-chart1" },
                              [
                                _c("dv-charts", {
                                  staticStyle: { height: "200px" },
                                  attrs: {
                                    option: _vm.configData.chartOption4,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "lccb-right" },
                    [
                      _c(
                        "dv-border-box-8",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            color: _vm.configData.borderBorder,
                            reverse: true,
                          },
                        },
                        [
                          _c("div", { staticClass: "lct-warp-v1" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "liy-common-title liy-common-title-v1",
                              },
                              [
                                _c("div", { staticClass: "lct-left" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lct-right" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lct-right-text1" },
                                    [_vm._v("产业兴旺")]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "liy-chart6" },
                              [
                                _c("dv-conical-column-chart", {
                                  staticStyle: { height: "170px" },
                                  attrs: {
                                    config: _vm.configData.chartOption5,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "liy-content-right" }, [
                _c(
                  "div",
                  { staticClass: "lc-right-top " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "liy-common-title liy-common-title-v1",
                            },
                            [
                              _c("div", { staticClass: "lct-left" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lct-right" }, [
                                _c("div", { staticClass: "lct-right-text1" }, [
                                  _vm._v("生态宜居"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-chart2" }, [
                            _c("div", { staticClass: "liy-chart2-content" }, [
                              _c(
                                "div",
                                { staticClass: "liy-char2-left" },
                                [
                                  _c("dv-active-ring-chart", {
                                    staticStyle: {
                                      height: "110px",
                                      "font-size": "12px",
                                    },
                                    attrs: {
                                      config: _vm.configData.chartOption2,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "liy-char2-right" }, [
                                _c("div", { staticClass: "liy-char2-list" }, [
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("蓝天卫士"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("10.28%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("空气监测"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("26.50%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("水质监测"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("20.50%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color4" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("土壤采样"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color4" }, [
                                      _vm._v("10.28%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color5" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("森林防火"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color5" }, [
                                      _vm._v("10.28%"),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-chart2-data" }, [
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img zoom",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img12.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("蓝天卫士（个）")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("79")]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img zoom",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img06.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("水质监测（个）")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("6")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-right-center " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "liy-common-title liy-common-title-v1",
                            },
                            [
                              _c("div", { staticClass: "lct-left" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lct-right" }, [
                                _c("div", { staticClass: "lct-right-text1" }, [
                                  _vm._v("共同富裕"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "250px" },
                                attrs: { option: _vm.configData.chartOption3 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-right-bottom" },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "liy-common-title liy-common-title-v1",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "lct-left",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToPages("center")
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "lct-left-more" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/data_images/liy-img47.png"),
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("更多")]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "lct-right" }, [
                                _c("div", { staticClass: "lct-right-text1" }, [
                                  _vm._v("平台&工具&服务"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-char4" }, [
                            _c("div", { staticClass: "lc4-list" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "lc4-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUrl(
                                        "http://www.ymxczx.com:18885/index"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("乡村振兴合伙人平台")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "lc4-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUrl(
                                        "https://lygy.bjedtek.com/"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("供应链服务平台")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "lc4-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUrl(
                                        "http://wenlv.page.sdmc.vip/#/"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("乡村文旅平台")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "lc4-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUrl(
                                        "http://36.137.247.31:18888/"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("清廉村居平台")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "lc4-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUrl(
                                        "http://36.137.247.31:18888/"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("阳光村务平台")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "lc4-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUrl(
                                        "http://test.b5net.com/cheguansuo/#/"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("金融服务平台")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("com-footer", { attrs: { menuIndex: 1 } }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "官桥简介",
                    visible: _vm.dialogTableVisible1,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogTableVisible1 = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "diog-index-contianer" }, [
                    _c("iframe", {
                      attrs: {
                        src: "http://www.tengzhou.gov.cn/zzq/zj/gqz/gzdt/index.html",
                        width: "100%",
                        height: "500",
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: { title: "视频简介", visible: _vm.dialogTableVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogTableVisible = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "diog-index-contianer" }, [
                    _c("video", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        muted: "muted",
                        type: "video/mp4",
                        controls: "controls",
                        loop: "-1",
                        src: require("@/assets/video/mda-mkt7af6acg8476pp.mp4"),
                      },
                      domProps: { muted: true },
                    }),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "center-body",
      style:
        "background-image: url(" + _vm.siteConfig.showroom_background + ")",
    },
    [
      _c("product-menu", { attrs: { name: "" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center-main" },
        [
          _c(
            "div",
            { staticClass: "center-top-list" },
            _vm._l(_vm.menuTopList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class:
                    index == 2 || index == 3
                      ? "center-top-item2"
                      : "center-top-item",
                },
                [
                  _c("div", { staticClass: "center-top-item-body" }, [
                    _c(
                      "div",
                      { staticClass: "top" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src: item.image_text,
                            fit: "cover",
                            "preview-src-list": [item.image_text],
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "label" }, [
                      _c(
                        "div",
                        {
                          staticClass: "overflow2",
                          on: {
                            click: function ($event) {
                              return _vm.goUrl(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.title))]
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "center-cen-list" },
            _vm._l(_vm.menuList, function (item, index) {
              return _c("div", { key: index, staticClass: "center-top-item" }, [
                _c("div", { staticClass: "center-top-item-body" }, [
                  _c(
                    "div",
                    { staticClass: "top" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: item.image_text,
                          fit: "cover",
                          "preview-src-list": [item.image_text],
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c(
                      "div",
                      {
                        staticClass: "overflow2",
                        on: {
                          click: function ($event) {
                            return _vm.goUrl(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                  ]),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "swiper-ww-container" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.menuDataList, function (menuItem, menuIndex) {
                return _c(
                  "div",
                  {
                    key: menuIndex,
                    staticClass: "swiper-slide",
                    on: {
                      mouseenter: _vm.swiperMouseenterContainer,
                      mouseleave: _vm.swiperMouseleaveContainer,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "liy-slide-list" },
                      _vm._l(menuItem, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item-warp",
                            attrs: { name: "scrollBox" },
                          },
                          [
                            _c("div", { staticClass: "item" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "item-body",
                                  staticStyle: { height: "100%" },
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: {
                                      fit: "cover",
                                      src: item.image_text,
                                      "preview-src-list": [item.image_text],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "item-label" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "overflow",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUrl(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.title) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.imgShow,
                width: "1200px",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.imgShow = $event
                },
                close: function ($event) {
                  _vm.imgShow = false
                },
              },
            },
            [
              _c("div", [
                _c("img", {
                  staticStyle: { width: "100%" },
                  attrs: { src: _vm.imgUrl, alt: "" },
                }),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

module.exports = {
  title: '数据大屏',
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,
  baseApi: process.env.VUE_APP_BASE_API || '/'
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _data = require("./api/data");
//
//
//
//
//
//
// 初始element-ui版本-
// "element-ui": "2.7.2",
var _default = exports.default = {
  name: 'App',
  created: function created() {
    var _this = this;
    (0, _data.getSiteConfig)().then(function (result) {
      _this.$store.commit('app/SET_SITE_CONFIG', result.data);
    });
  }
};
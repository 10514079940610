var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "liy-menu-list" }, [
        _c(
          "div",
          {
            staticClass: "m-left",
            staticStyle: { display: "flex" },
            on: { click: _vm.olck },
          },
          [
            _c("img", {
              staticStyle: { width: "12rem", display: "block" },
              attrs: { src: _vm.siteConfig.showroom_logo, alt: "" },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "m-right" }, [
          _c(
            "div",
            {
              staticClass: "mr-item",
              class: _vm.name == "book" ? "current" : "",
              on: {
                click: function ($event) {
                  return _vm.goUrlPath("book")
                },
              },
            },
            [_vm._v("\n        电子图册\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mr-item mr-product",
              class: _vm.name == "center" ? "current" : "",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.goUrlPath("center")
                },
              },
            },
            [
              _c("div", [_vm._v("产品方案")]),
              _vm._v(" "),
              _c("div", { staticClass: "mr-item-abs" }, [
                _c(
                  "div",
                  { staticClass: "mr-product-content" },
                  _vm._l(_vm.menuList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "mr-product-li",
                        class: _vm.product_type_id == item.id ? "current" : "",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.subMenuGoUrlPath(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.title) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mr-item",
              class: _vm.name == "news" ? "current" : "",
              on: {
                click: function ($event) {
                  return _vm.goUrlPath("news")
                },
              },
            },
            [_vm._v("\n        新闻资讯\n      ")]
          ),
          _vm._v(" "),
          _vm.siteConfig.showroom_morenav == 1
            ? _c(
                "div",
                {
                  staticClass: "mr-item",
                  class: _vm.name == "history" ? "current" : "",
                  on: {
                    click: function ($event) {
                      return _vm.goUrlPath("history")
                    },
                  },
                },
                [
                  _c("div", [_vm._v("发展历程")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mr-item-abs" }, [
                    _c(
                      "div",
                      { staticClass: "mr-product-content" },
                      _vm._l(_vm.listZhongxinMenu, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index + "1",
                            staticClass: "mr-product-li",
                            class:
                              _vm.product_type_id == item.id ? "current" : "",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.subMenuGoUrlPath(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.title) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "视频简介", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "diog-index-contianer" }, [
            _c("video", {
              staticStyle: { width: "100%" },
              attrs: {
                muted: "muted",
                type: "video/mp4",
                controls: "controls",
                loop: "-1",
                src: _vm.dialogTableUrl,
              },
              domProps: { muted: true },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "zoom-option" }, [
        _c(
          "div",
          {
            staticClass: "zoom-item",
            on: {
              click: function ($event) {
                return _vm.zoom(1)
              },
            },
          },
          [_vm._v("放大")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "zoom-item",
            on: {
              click: function ($event) {
                return _vm.zoom(0)
              },
            },
          },
          [_vm._v("还原")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "zoom-item",
            on: {
              click: function ($event) {
                return _vm.zoom(-1)
              },
            },
          },
          [_vm._v("缩小")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _data = require("@/api/data");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    name: {
      type: [String],
      default: ""
    }
  },
  data: function data() {
    return {
      zoomStep: 1,
      menuList: [],
      isProduct: false,
      isProductAbs: false,
      dialogTableVisible: false,
      dialogTableUrl: "",
      product_type_id: 0,
      clickCount: 0,
      resetTimerId: null,
      listZhongxinMenu: [{
        id: 100,
        title: "关于我们",
        url: "/about",
        type: 0
      }, {
        id: 101,
        title: "媒体宣传",
        url: "/list?type=8",
        type: 0
      }, {
        id: 102,
        title: "领导关怀",
        url: "/list?type=9",
        type: 0
      }, {
        id: 103,
        title: "荣誉获奖",
        url: "/list?type=10",
        type: 0
      }]
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    siteConfig: function siteConfig(state) {
      return state.app.siteConfig;
    }
  })),
  mounted: function mounted() {
    var _this = this;
    setTimeout(function () {
      console.log(_this.siteConfig);
    }, 999);
    (0, _data.getDataProjectList)().then(function (response) {
      _this.menuList = response.data;
    });
  },
  methods: {
    olck: function olck() {
      var _this2 = this;
      if (this.clickCount >= 4) {
        location.href = "/#/iframes";
        clearTimeout(this.resetTimerId);
        this.clickCount = 0;
      } else {
        this.clickCount++;
        this.resetTimerId = setTimeout(function () {
          _this2.clickCount = 0;
        }, 5000);
      }
    },
    /* 主菜单跳转 */goUrlPath: function goUrlPath(url) {
      this.$router.push({
        path: url
      });
    },
    /* 子菜单跳转 */subMenuGoUrlPath: function subMenuGoUrlPath(item) {
      this.product_type_id = item.id;
      if (item.type == 1) {
        window.open(item.url);
      } else if (item.type == 2) {
        this.dialogTableUrl = item.url;
        this.dialogTableVisible = true;
      } else {
        this.$router.push({
          path: item.url
        });
      }
    },
    zoom: function zoom(way) {
      if (way == 1) {
        this.zoomStep += 0.1;
      } else if (way == -1) {
        this.zoomStep -= 0.1;
      } else {
        this.zoomStep = 0;
      }
      document.body.style.zoom = this.zoomStep;
    }
  }
};
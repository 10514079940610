var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    _vm._l(_vm.listFrame, function (item) {
      return _c(
        "div",
        {
          staticClass: "item_iframe",
          on: {
            click: function ($event) {
              return _vm.onFrameClick(item, _vm.index)
            },
          },
        },
        [_c("iframe", { attrs: { src: item.src } })]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("com-header"),
      _vm._v(" "),
      _c("div", { staticClass: "liy-map-container" }, [
        _c("div", {
          staticClass: "map-container",
          attrs: { id: "liy-map-car-container" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "liy-content" }, [
          _c("div", { staticClass: "liy-content-left" }, [
            _c(
              "div",
              { staticClass: "lc-left-top " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c("div", { staticClass: "liy-common-title" }, [
                        _c("div", { staticClass: "lct-left" }, [
                          _vm._v("环保数据"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "lcl-party" }, [
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/farm_icon1.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("公园绿地面积(m²)")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("79,564"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/farm_icon2.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("生活垃圾产生(m³)")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("6,252"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/farm_icon3.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("保洁人员数量(个)")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("4,150"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/farm_icon4.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("垃圾清理站数量(个)")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("7,222"),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-left-center " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c("div", { staticClass: "liy-common-title" }, [
                        _c("div", { staticClass: "lct-left" }, [
                          _vm._v("设备分布"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "liy-chart2",
                          staticStyle: { "margin-top": "20px" },
                        },
                        [
                          _c("div", { staticClass: "liy-chart2-content" }, [
                            _c(
                              "div",
                              { staticClass: "liy-char2-left" },
                              [
                                _c("dv-active-ring-chart", {
                                  staticStyle: {
                                    height: "110px",
                                    "font-size": "12px",
                                  },
                                  attrs: {
                                    config: _vm.configData.chartOption2,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-char2-right" }, [
                              _c("div", { staticClass: "liy-char2-list" }, [
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("市生态环境局"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("2651"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("36.24%"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("市规划自然资源局"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("1654"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("26.50%"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("市水务局"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("965"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("16.30%"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color4" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("市水务局"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color4" }, [
                                      _vm._v("214"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color4" }, [
                                      _vm._v("10.50%"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color5" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("市林业和园林局"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("span", { staticClass: "lc2l-color5" }, [
                                      _vm._v("127"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color5" }, [
                                      _vm._v("5.50%"),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-left-bottom" },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c("div", { staticClass: "liy-common-title" }, [
                        _c("div", { staticClass: "lct-left" }, [
                          _vm._v("工程治理"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "liy-chart1" },
                        [
                          _c("dv-scroll-board", {
                            staticStyle: {
                              width: "95%",
                              height: "150px",
                              margin: "0 auto",
                              "margin-top": "10px",
                            },
                            attrs: { config: _vm.configData.tableData },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "liy-content-right" }, [
            _c(
              "div",
              { staticClass: "lc-right-top " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c(
                        "div",
                        { staticClass: "liy-common-title liy-common-title-v1" },
                        [
                          _c("div", { staticClass: "lct-left" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lct-right" }, [
                            _c("div", { staticClass: "lct-right-text1" }, [
                              _vm._v("空气指数统计"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "liy-chart2" },
                        [
                          _c("dv-charts", {
                            staticStyle: { height: "250px", width: "100%" },
                            attrs: { option: _vm.configData.chartOption6 },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-right-center " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c(
                        "div",
                        { staticClass: "liy-common-title liy-common-title-v1" },
                        [
                          _c("div", { staticClass: "lct-left" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lct-right" }, [
                            _c("div", { staticClass: "lct-right-text1" }, [
                              _vm._v("污水来源统计"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "liy-chart2" }, [
                        _c("div", { staticClass: "liy-chart2-content" }, [
                          _c(
                            "div",
                            { staticClass: "liy-char2-left" },
                            [
                              _c("dv-active-ring-chart", {
                                staticStyle: {
                                  height: "110px",
                                  "font-size": "12px",
                                },
                                attrs: { config: _vm.configData.chartOption2 },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-char2-right" }, [
                            _c("div", { staticClass: "liy-char2-list" }, [
                              _c("div", { staticClass: "liy-char2-li" }, [
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color1" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("河流和湖泊污染"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color1" }, [
                                    _vm._v("2651"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color1" }, [
                                    _vm._v("36.24%"),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "liy-char2-li" }, [
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color2" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("雨水排放"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color2" }, [
                                    _vm._v("1654"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color2" }, [
                                    _vm._v("26.50%"),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "liy-char2-li" }, [
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color3" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("工业废水"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color3" }, [
                                    _vm._v("965"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color3" }, [
                                    _vm._v("16.30%"),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "liy-char2-li" }, [
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color4" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("城市污水"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color4" }, [
                                    _vm._v("214"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color4" }, [
                                    _vm._v("10.50%"),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "liy-char2-li" }, [
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color5" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("市林业和园林局"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("span", { staticClass: "lc2l-color5" }, [
                                    _vm._v("127"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color5" }, [
                                    _vm._v("5.50%"),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-right-bottom" },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c(
                        "div",
                        { staticClass: "liy-common-title liy-common-title-v1" },
                        [
                          _c("div", { staticClass: "lct-left" }, [
                            _c(
                              "div",
                              {
                                staticClass: "lct-left-more",
                                on: {
                                  click: function ($event) {
                                    return _vm.goUrl(
                                      "http://wenlv.page.sdmc.vip/#/tourism_list"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img47.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("更多")]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lct-right" }, [
                            _c("div", { staticClass: "lct-right-text1" }, [
                              _vm._v("生态旅游推荐"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "liy-char4" }, [
                        _c("div", { staticClass: "lc4-list" }, [
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "https://baike.baidu.com/item/%E5%8C%97%E8%BE%9B%E9%81%97%E5%9D%80/8778353?fr=ge_ala"
                                  )
                                },
                              },
                            },
                            [_vm._v("北辛遗址")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "https://baike.baidu.com/item/%E8%96%9B%E5%9B%BD%E6%95%85%E5%9F%8E/7421030?fr=ge_ala"
                                  )
                                },
                              },
                            },
                            [_vm._v("薛国故城")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "https://baike.baidu.com/item/%E5%89%8D%E6%8E%8C%E5%A4%A7%E9%81%97%E5%9D%80/7271088?fr=ge_ala"
                                  )
                                },
                              },
                            },
                            [_vm._v("前掌大遗址")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "https://baike.baidu.com/item/%E5%AD%9F%E5%B0%9D%E5%90%9B%E5%A2%93/6471111?fr=ge_ala"
                                  )
                                },
                              },
                            },
                            [_vm._v("田文、田婴墓")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "https://baike.baidu.com/item/%E6%AF%9B%E9%81%82%E5%A2%93/14213002?fr=ge_ala"
                                  )
                                },
                              },
                            },
                            [_vm._v("毛遂墓址")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "https://baike.baidu.com/item/%E6%B1%89%E7%94%BB%E5%83%8F%E7%9F%B3/2868730?fr=ge_ala"
                                  )
                                },
                              },
                            },
                            [_vm._v("汉画像石")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("com-footer", { attrs: { menuIndex: 4 } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "liy-content-center" }, [
      _c("div", { staticClass: "lc-center-top" }),
      _vm._v(" "),
      _c("div", { staticClass: "lc-center-center" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
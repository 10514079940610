"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _productMenu = _interopRequireDefault(require("./../components/product-menu.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//轮播图
var _default = exports.default = {
  components: {
    productMenu: _productMenu.default
  },
  data: function data() {
    return {
      mySwiper: null
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    siteConfig: function siteConfig(state) {
      return state.app.siteConfig;
    }
  })),
  destroyed: function destroyed() {},
  mounted: function mounted() {
    this.mySwiper = new Swiper('.mn-swiper', {
      autoplay: true,
      touchRatio: 1,
      speed: 3000,
      //匀速时间
      loop: true,
      freeMode: true,
      //设置为true则变为free模式
      slidesPerView: 4,
      slidesPerGroup: 1
    });
  },
  methods: {
    swiperArrowLeft: function swiperArrowLeft() {
      this.mySwiper.swipePrev();
    },
    swiperArrowRight: function swiperArrowRight() {
      this.mySwiper.swipeNext();
    },
    //鼠标移入悬停
    swiperMouseenterContainer: function swiperMouseenterContainer() {
      console.log('哈哈哈');
      this.mySwiper.stopAutoplay();
    },
    //鼠标移出滚动
    swiperMouseleaveContainer: function swiperMouseleaveContainer() {
      this.mySwiper.startAutoplay();
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "center-body",
      style:
        "background-image: url(" + _vm.siteConfig.showroom_background + ")",
    },
    [
      _c("product-menu", { attrs: { name: "history" } }),
      _vm._v(" "),
      _c("div", { staticClass: "center-main" }, [
        _c("div", { staticClass: "about-main2" }, [
          _c("div", { staticClass: "licheng-bg" }, [
            _c("img", {
              staticClass: "quxian",
              attrs: { src: require("@/assets/data_images/Vector.png") },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "warper pr" }, [
              _c("div", { staticClass: "licheng-content swiper-container" }, [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(_vm.menuTopList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "swiper-slide" },
                      [
                        _c(
                          "ul",
                          { staticClass: "clearfix" },
                          _vm._l(item, function (pubItem, pubIndex) {
                            return _c(
                              "li",
                              { key: pubIndex, class: pubItem.class_name },
                              [
                                _c("a", { staticClass: "clearfix" }, [
                                  pubItem.class_name == "item2" ||
                                  pubItem.class_name == "item4"
                                    ? _c("div", { staticClass: "lc-left" }, [
                                        _c("span", { staticClass: "date" }, [
                                          _vm._v(_vm._s(pubItem.history)),
                                        ]),
                                        _vm._v(" "),
                                        _c("i", { staticClass: "line" }),
                                      ])
                                    : _c("div", { staticClass: "lc-left" }, [
                                        _c("i", { staticClass: "line" }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "date" }, [
                                          _vm._v(_vm._s(pubItem.history)),
                                        ]),
                                      ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "lc-right" }, [
                                    _c("div", { staticClass: "right-text" }, [
                                      _c("h3", [_vm._v(_vm._s(pubItem.title))]),
                                      _vm._v(" "),
                                      _c("p", [_vm._v(_vm._s(pubItem.desc))]),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "swiper-ww-container" }, [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.menuDataList, function (menuItem, menuIndex) {
              return _c(
                "div",
                {
                  key: menuIndex,
                  staticClass: "swiper-slide",
                  on: {
                    mouseenter: _vm.swiperMouseenterContainer,
                    mouseleave: _vm.swiperMouseleaveContainer,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "liy-slide-list" },
                    _vm._l(menuItem, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "item-warp",
                          attrs: { name: "scrollBox" },
                        },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c("div", { staticClass: "item-body" }, [
                              _c("img", {
                                attrs: { src: item.image_text, alt: "" },
                                on: {
                                  dblclick: function ($event) {
                                    return _vm.goUrlPath(item.image_text)
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-label" }, [
                            _c(
                              "div",
                              {
                                staticClass: "overflow",
                                on: {
                                  click: function ($event) {
                                    return _vm.goUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "center-body",
      style:
        "background-image: url(" + _vm.siteConfig.showroom_background + ")",
    },
    [
      _c("product-menu", {
        attrs: { product_type_id: _vm.product_type_id, name: "book" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "center-main" }, [
        _c("div", { staticClass: "nav-container" }, [
          _c(
            "a",
            {
              staticClass: "nav-button",
              attrs: {
                href: _vm.baseApi + "/PDygvSJkIC.php/zt/screen/index",
                target: "zt",
              },
            },
            [_vm._v("展示中心")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-button",
              attrs: {
                href: "https://vr.3d66.com/vr/index_detail_4304506.asp",
                target: "vr",
              },
            },
            [_vm._v("全景展厅")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-button",
              attrs: {
                href: "https://vr.justeasy.cn/view/fuc17121x4d81c83-1712304836.html",
                target: "vr",
              },
            },
            [_vm._v("全景办公室")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "nav-button", on: { dblclick: _vm.gotoList } },
            [_vm._v("更多")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "certify",
            class: _vm.is_show == true ? "certify-show" : "",
            attrs: { id: "certify" },
          },
          [
            _c("div", { staticClass: "swiper-container" }, [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                _vm._l(_vm.bookList, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "swiper-slide",
                      attrs: { index: index, "data-index": index },
                    },
                    [
                      _c("img", {
                        staticClass: "liy-img",
                        attrs: { src: item.image_text },
                      }),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(item.title))]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "swiper-pagination" }),
            _vm._v(" "),
            _c("div", { staticClass: "swiper-button-prev" }),
            _vm._v(" "),
            _c("div", { staticClass: "swiper-button-next" }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
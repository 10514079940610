"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _map = require("@/utils/map");
var _comm_header = _interopRequireDefault(require("./../components/comm_header.vue"));
var _comm_footer = _interopRequireDefault(require("./../components/comm_footer.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Index",
  components: {
    comHeader: _comm_header.default,
    comFooter: _comm_footer.default
  },
  data: function data() {
    return {
      configData: {
        borderBorder: ['#095A3E', '#27F38E'],
        chartOption1: {
          title: {
            text: '三务管理数据变化图',
            show: false,
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '10%',
            top: '20%',
            data: [{
              name: '数据一',
              color: "#41E4BB"
            }, {
              name: '数据二',
              color: "#E44141"
            }, {
              name: '数据三',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '村务',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '党务',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '财务',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }],
          color: ['#41E4BB', '#E44141', '#0091FF']
        },
        chartOption2: {
          radius: '80%',
          activeRadius: '85%',
          digitalFlopStyle: {
            fontSize: 10,
            fill: '#fff'
          },
          data: [{
            name: '市生态环境局',
            value: 2651
          }, {
            name: '市规划自然资源局',
            value: 1654
          }, {
            name: '市水务局',
            value: 965
          }, {
            name: '市水务局',
            value: 214
          }, {
            name: '市林业和园林局',
            value: 127
          }],
          color: ['#27F38E', '#59FFFF', '#FF386B', '#FFFFFF', '#F5E74F'],
          showOriginValue: true,
          lineWidth: 10
        },
        chartOption3: {
          title: {
            text: '周销售额趋势',
            show: false
          },
          legend: {
            data: ['外地游客', '本地游客'],
            show: true
          },
          xAxis: {
            name: '第一周',
            data: ['00:00', '04:00', '8:00', '12:00', '16:00', '20:00'],
            textStyle: {
              color: '#fff'
            }
          },
          yAxis: {
            name: '游客数量',
            data: 'value'
          },
          series: [{
            name: '外地游客',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar',
            textStyle: {
              color: '#fff'
            }
            // shapeType: 'leftEchelon'
          }, {
            name: '本地游客',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar'
            // shapeType: 'rightEchelon'
          }]
        },
        chartOption4: {
          title: {
            text: '三务管理数据变化图',
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '10%',
            top: '20%',
            data: [{
              name: '设备接入',
              color: "#41E4BB"
            }, {
              name: '数据共享',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '22:00'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '设备接入',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar'
          }, {
            name: '数据共享',
            data: [1800, 5822, 3500, 1100, 2000, 2000, 4500],
            type: 'bar'
          }],
          color: ['#41E4BB', '#0091FF']
        },
        chartOption5: {
          data: [{
            name: '家庭农场',
            value: 55
          }, {
            name: '种养大户',
            value: 120
          }, {
            name: ' 合 作 社  ',
            value: 71
          }, {
            name: '龙头企业',
            value: 66
          }],
          showValue: true,
          columnColor: 'rgba(39, 243, 142, 0.4)'
        },
        chartOption6: {
          title: {
            text: '周销售额趋势',
            show: false
          },
          legend: {
            data: ['空气质量指数（AQI）', 'PM2.5浓度', 'PM10浓度', '臭氧（O3）浓度', '二氧化硫（SO2）浓度'],
            right: '10%',
            top: '10%',
            textStyle: {
              fill: '#fff'
            }
          },
          xAxis: {
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLabel: {
              style: {
                fill: '#E6F7FF'
              }
            }
          },
          yAxis: {
            name: '指数',
            data: 'value',
            nameTextStyle: {
              fill: '#E6F7FF',
              fontSize: 10
            },
            axisLabel: {
              style: {
                fill: '#E6F7FF'
              }
            }
          },
          series: [{
            name: '空气质量指数（AQI）',
            data: [100, 210, 110, 200, 100, 300, 304],
            type: 'line',
            // stack: 'a',
            fill: {
              show: true
            },
            color: '#41E4BB'
          }, {
            name: 'PM2.5浓度',
            data: [220, 230, 120, 100, 300, 200, 315],
            type: 'line',
            // stack: 'a',
            smooth: true,
            color: '#E44141'
          }, {
            name: 'PM10浓度',
            data: [200, 290, 100, 300, 210, 400, 385],
            type: 'line',
            color: '#0091FF'
          }, {
            name: '臭氧（O3）浓度',
            data: [174, 290, 100, 300, 230, 420, 325],
            type: 'line',
            color: '#F5E74F'
          }, {
            name: '二氧化硫（SO2）浓度',
            data: [213, 105, 117, 98, 289, 210, 285],
            type: 'line',
            color: '#6CE5FF'
          }]
        },
        chartOption7: {
          title: {
            text: '周销售额趋势',
            show: false
          },
          legend: {
            data: ['绿地', '湖泊'],
            right: '10%',
            top: '20%'
          },
          xAxis: {
            name: '第一周',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月']
          },
          yAxis: {
            name: '销售额',
            data: 'value'
          },
          series: [{
            name: '绿地',
            data: [234, 134, 214, 315, 206, 270, 198],
            type: 'line',
            fill: {
              show: true
            }
          }, {
            name: '湖泊',
            data: [119, 219, 319, 209, 306, 213, 119],
            type: 'line',
            smooth: true
          }]
        },
        chartOption: {
          title: {
            text: '周销售额趋势',
            style: {
              fill: '#fff'
            }
          },
          xAxis: {
            name: '',
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '销售额',
            data: 'value',
            nameTextStyle: {
              fill: '#fff'
            },
            axisLine: {
              style: {
                stroke: '#fff'
              }
            },
            axisTick: {
              style: {
                stroke: '#fff'
              }
            },
            axisLabel: {
              style: {
                fill: '#fff',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#fff'
              }
            }
          },
          series: [{
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar',
            gradient: {
              color: ['#37a2da', '#67e0e3']
            }
          }]
        },
        tableData: {
          header: ['机构', '项目名称', '开始时间'],
          headerBGC: 'rgba(39,243,142,0.1)',
          oddRowBGC: 'rgba(0,0,0,0.01)',
          evenRowBGC: 'rgba(0,0,0,0.01)',
          data: [['市政府', '泥石流治理', '2023.05.18'], ['信息技术部门', '百亩银杏林打造', '2023.05.18'], ['城市规划部门', '隧道工程治理', '2023.05.18'], ['交通部门', '水边清洁', '2023.05.18'], ['信息技术部门', '温度传感器', '2023.05.18'], ['交通部门', '温度传感器', '2023.05.18'], ['信息技术部门', '水边清洁', '2023.05.18']]
        }
      },
      mapInstance: null,
      isMap: false,
      AMap: null
    };
  },
  created: function created() {
    this.getMapEventData();
  },
  methods: {
    getMapEventData: function getMapEventData() {
      var _this = this;
      if (this.isMap == false) {
        (0, _map.loadMap)(function (AMap) {
          if (window.mapInstance) {
            _this.destroyMap(window.mapInstance);
          }
          _this.mapInstance = new AMap.Map("liy-map-car-container", {
            mapStyle: 'amap://styles/blue',
            //设置地图的显示样式
            center: [117.214, 34.9268],
            zoom: 16,
            resizeEnable: true
          });
          window.mapInstance = _this.mapInstance;
          _this.isMap = true;
          _this.AMap = AMap;
          // 地图加载完成
          _this.mapInstance.on('complete', function (res) {});
        });
      } else {}
    },
    goUrl: function goUrl(url) {
      window.open(url);
    },
    goToPages: function goToPages(url) {
      this.$router.push({
        path: url
      });
    },
    destroyMap: function destroyMap(map) {
      if (map) {
        map.destroy();
        window.mapInstance = null;
        window.mapInstanceWWE = null;
      }
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "center-body",
      style:
        "background-image: url(" + _vm.siteConfig.showroom_background + ")",
    },
    [
      _c("product-menu", {
        attrs: { product_type_id: _vm.product_type_id, name: "center" },
        on: { setProductTypeId: _vm.setProductTypeId },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "center-main" }, [
        _c(
          "div",
          { staticClass: "center-top-list" },
          _vm._l(_vm.menuTopList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                class:
                  index == 2 || index == 3
                    ? "center-top-item2"
                    : "center-top-item",
                on: {
                  click: function ($event) {
                    return _vm.goUrl(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "center-top-item-body" }, [
                  _c("div", { staticClass: "top" }, [
                    _c("img", { attrs: { src: item.image_text, alt: "" } }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("div", { staticClass: "overflow2" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "center-cen-list " },
          _vm._l(_vm.menuList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "center-top-item",
                on: {
                  click: function ($event) {
                    return _vm.goUrl(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "center-top-item-body" }, [
                  _c("div", { staticClass: "top" }, [
                    _c("img", { attrs: { src: item.image_text, alt: "" } }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("div", { staticClass: "overflow2" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "swiper-ww-container" }, [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.menuDataList, function (menuItem, menuIndex) {
              return _c(
                "div",
                {
                  key: menuIndex,
                  staticClass: "swiper-slide",
                  on: {
                    mouseenter: _vm.swiperMouseenterContainer,
                    mouseleave: _vm.swiperMouseleaveContainer,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "liy-slide-list" },
                    _vm._l(menuItem, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "item-warp",
                          attrs: { name: "scrollBox" },
                        },
                        [
                          _c("div", { staticClass: "item" }, [
                            _c("div", { staticClass: "item-body" }, [
                              _c("img", {
                                attrs: { src: item.image_text, alt: "" },
                                on: {
                                  dblclick: function ($event) {
                                    return _vm.goUrl(item)
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-label" }, [
                            _c(
                              "div",
                              {
                                staticClass: "overflow",
                                on: {
                                  click: function ($event) {
                                    return _vm.goUrl(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
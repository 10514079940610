var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("com-header"),
      _vm._v(" "),
      _c("div", { staticClass: "liy-map-container" }, [
        _c("div", {
          staticClass: "map-container",
          attrs: { id: "liy-map-car-container" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "liy-content" }, [
          _c("div", { staticClass: "liy-content-left" }, [
            _c(
              "div",
              { staticClass: "lc-left-top " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c("div", { staticClass: "liy-common-title" }, [
                        _c("div", { staticClass: "lct-left" }, [
                          _vm._v("数据统计"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "lcl-party" }, [
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/data_images/liy-img21.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("4564人"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("正式党员")]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/data_images/liy-img21.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("1313人"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("流动党员")]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/data_images/liy-img21.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("216人"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("发展党员")]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/data_images/liy-img21.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("457人"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("预备党员")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-left-center " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c("div", { staticClass: "liy-common-title" }, [
                        _c("div", { staticClass: "lct-left" }, [
                          _vm._v("年龄分布"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lct-right" }, [
                          _c("div", { staticClass: "lct-right-more" }, [
                            _c("span", [_vm._v("更多")]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/data_images/liy-img09.png"),
                                alt: "",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "liy-chart2",
                          staticStyle: { "margin-top": "20px" },
                        },
                        [
                          _c("div", { staticClass: "liy-chart2-content" }, [
                            _c(
                              "div",
                              { staticClass: "liy-char2-left" },
                              [
                                _c("dv-active-ring-chart", {
                                  staticStyle: {
                                    height: "110px",
                                    "font-size": "12px",
                                  },
                                  attrs: {
                                    config: _vm.configData.chartOption2,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-char2-right" }, [
                              _c("div", { staticClass: "liy-char2-list" }, [
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("span", { staticClass: "lc2l-color1" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("28岁以下"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color1" }, [
                                    _vm._v("2651人"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color1" }, [
                                    _vm._v("36.24%"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("span", { staticClass: "lc2l-color2" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("29-35岁"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color2" }, [
                                    _vm._v("1654人"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color2" }, [
                                    _vm._v("26.50%"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("span", { staticClass: "lc2l-color3" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("36-50岁"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color3" }, [
                                    _vm._v("965人"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color3" }, [
                                    _vm._v("16.30%"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("span", { staticClass: "lc2l-color4" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("51-60岁"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color4" }, [
                                    _vm._v("214人"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color4" }, [
                                    _vm._v("10.50%"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "liy-char2-li" }, [
                                  _c("span", { staticClass: "lc2l-color5" }, [
                                    _vm._v("●"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-text2" }, [
                                    _vm._v("61岁及以上"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color5" }, [
                                    _vm._v("127人"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lc2l-color5" }, [
                                    _vm._v("5.50%"),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-left-bottom" },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c("div", { staticClass: "liy-common-title" }, [
                        _c("div", { staticClass: "lct-left" }, [
                          _vm._v("游客统计"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "liy-chart1" },
                        [
                          _c("dv-charts", {
                            staticStyle: { height: "210px", width: "100%" },
                            attrs: { option: _vm.configData.chartOption3 },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "liy-content-right" }, [
            _c(
              "div",
              { staticClass: "lc-right-top " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c(
                        "div",
                        { staticClass: "liy-common-title liy-common-title-v1" },
                        [
                          _c("div", { staticClass: "lct-left" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lct-right" }, [
                            _c("div", { staticClass: "lct-right-text1" }, [
                              _vm._v("党建活动"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "liy-chart2" }, [
                        _c("div", { staticClass: "liy-table-list" }, [
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-table-item" }, [
                            _c("div", { staticClass: "liy-table-item-left" }, [
                              _vm._v("十位教授受邀参加文化艺术中心市民开放..."),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "liy-table-item-right" }, [
                              _vm._v("2023-10-10"),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-right-bottom" },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c(
                        "div",
                        { staticClass: "liy-common-title liy-common-title-v1" },
                        [
                          _c("div", { staticClass: "lct-left" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lct-right" }, [
                            _c("div", { staticClass: "lct-right-text1" }, [
                              _vm._v("文化遗址"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "liy-char4" }, [
                        _c("div", { staticClass: "lc4-list" }, [
                          _c("div", { staticClass: "lc4-item" }, [
                            _c("img", {
                              attrs: {
                                src: "/static/index_images/d8f9d72a6059252d92ee2def329b033b5ab5b95b.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "lc4-item-label" }, [
                              _vm._v("北辛遗址"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lc4-item" }, [
                            _c("img", {
                              attrs: {
                                src: "/static/index_images/2cf5e0fe9925bc31d2ab3e465cdf8db1ca13707d.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "lc4-item-label" }, [
                              _vm._v("薛国故城"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lc4-item" }, [
                            _c("img", {
                              attrs: {
                                src: "/static/index_images/962bd40735fae6cd7b89a50880e5182442a7d933a106.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "lc4-item-label" }, [
                              _vm._v("前掌大遗址"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lc4-item" }, [
                            _c("img", {
                              attrs: {
                                src: "/static/index_images/b6b36b52eb094a89a6102a258a3b93d1_990_0_max_jpg.jpg",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "lc4-item-label" }, [
                              _vm._v("毛遂墓址"),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("com-footer", { attrs: { menuIndex: 2 } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "liy-content-center" }, [
      _c("div", { staticClass: "lc-center-top" }),
      _vm._v(" "),
      _c("div", { staticClass: "lc-center-center" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "center-body",
      style:
        "background-image: url(" + _vm.siteConfig.showroom_background + ")",
    },
    [
      _c("product-menu", { attrs: { name: "about" } }),
      _vm._v(" "),
      _c("div", { staticClass: "center-main" }, [
        _c(
          "div",
          { staticClass: "list-image", attrs: { id: "list-image" } },
          _vm._l(_vm.listImage, function (src) {
            return _c("div", { staticClass: "image-item" }, [
              _c("img", { attrs: { src: src } }),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
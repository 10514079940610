"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vueData = void 0;
var vueData = exports.vueData = {
  configData: {
    borderBorder: ['#095A3E', '#27F38E'],
    digConfig1: {
      number: [0],
      style: {
        fill: '#27F38E'
      },
      fontSize: 24
    },
    digConfig2: {
      number: [0],
      style: {
        fill: '#27F38E'
      },
      fontSize: 24
    },
    digConfig3: {
      number: [0],
      style: {
        fill: '#27F38E'
      },
      fontSize: 24
    },
    digConfig4: {
      number: [0],
      style: {
        fill: '#27F38E'
      },
      fontSize: 24
    },
    digConfig5: {
      number: [0],
      style: {
        fill: '#27F38E'
      },
      fontSize: 24
    },
    digConfig6: {
      number: [0],
      style: {
        fill: '#27F38E'
      },
      fontSize: 24
    },
    chartOption1: {
      title: {
        text: '三务管理数据变化图',
        offset: [-150, -18],
        style: {
          fill: '#fff',
          fontSize: 13
        }
      },
      legend: {
        right: '10%',
        top: '20%',
        data: [{
          name: '村务',
          color: "#41E4BB"
        }, {
          name: '党务',
          color: "#E44141"
        }, {
          name: '财务',
          color: "#0091FF"
        }],
        textStyle: {
          fontFamily: 'Arial',
          fontSize: 13,
          fill: '#fff'
        }
      },
      grid: {
        left: '15%'
      },
      xAxis: {
        name: '',
        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
        axisLine: {
          style: {
            stroke: '#2F626D'
          },
          lineStyle: {
            color: '#333'
          }
        },
        axisTick: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisLabel: {
          style: {
            fill: '#2F626D',
            fontSize: 12
          }
        },
        splitLine: {
          style: {
            stroke: '#2F626D'
          }
        }
      },
      yAxis: {
        name: '',
        data: 'value',
        axisLine: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisTick: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisLabel: {
          style: {
            fill: '#2F626D',
            fontSize: 12
          }
        },
        splitLine: {
          style: {
            stroke: '#2F626D',
            lineDash: [5, 5]
          }
        }
      },
      series: [{
        name: '村务',
        data: [104, 110, 108, 115, 120, 116, 120],
        type: 'line',
        lineStyle: {
          lineWidth: 2
        }
      }, {
        name: '党务',
        data: [86, 88, 92, 90, 64, 80, 96],
        type: 'line',
        lineStyle: {
          lineWidth: 2
        }
      }, {
        name: '财务',
        data: [68, 98, 67, 85, 76, 80, 92],
        type: 'line',
        lineStyle: {
          lineWidth: 2
        }
      }],
      color: ['#41E4BB', '#E44141', '#0091FF']
    },
    chartOption2: {
      radius: '80%',
      activeRadius: '85%',
      digitalFlopStyle: {
        fontSize: 10,
        fill: '#fff'
      },
      data: [{
        name: '蓝天卫士',
        value: 55
      }, {
        name: '空气监测',
        value: 120
      }, {
        name: '水质监测',
        value: 78
      }, {
        name: '土壤采样',
        value: 66
      }, {
        name: '森林防火',
        value: 80
      }],
      color: ['#27F38E', '#59FFFF', '#FF386B', '#FFFFFF', '#F5E74F'],
      showOriginValue: true,
      lineWidth: 10
    },
    chartOption3: {
      title: {
        text: '产权交易项目分析',
        offset: [-150, -18],
        style: {
          fill: '#fff',
          fontSize: 13
        }
      },
      legend: {
        right: '2%',
        top: '16%',
        data: [{
          name: '需求项目',
          color: "#41E4BB"
        }, {
          name: '供应项目',
          color: "#E44141"
        }, {
          name: '成交项目',
          color: "#0091FF"
        }],
        textStyle: {
          fontFamily: 'Arial',
          fontSize: 13,
          fill: '#fff'
        }
      },
      grid: {
        left: '15%'
      },
      xAxis: {
        name: '',
        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
        axisLine: {
          style: {
            stroke: '#2F626D'
          },
          lineStyle: {
            color: '#333'
          }
        },
        axisTick: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisLabel: {
          style: {
            fill: '#2F626D',
            fontSize: 12
          }
        },
        splitLine: {
          style: {
            stroke: '#2F626D'
          }
        }
      },
      yAxis: {
        name: '',
        data: 'value',
        axisLine: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisTick: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisLabel: {
          style: {
            fill: '#2F626D',
            fontSize: 12
          }
        },
        splitLine: {
          style: {
            stroke: '#2F626D',
            lineDash: [5, 5]
          }
        }
      },
      series: [{
        name: '需求项目',
        data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
        type: 'line',
        stack: 'a',
        lineStyle: {
          lineWidth: 2
        }
      }, {
        name: '供应项目',
        data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
        type: 'line',
        stack: 'a',
        lineStyle: {
          lineWidth: 2
        }
      }, {
        name: '成交项目',
        data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
        type: 'line',
        stack: 'a',
        lineStyle: {
          lineWidth: 2
        }
      }],
      color: ['#41E4BB', '#E44141', '#0091FF']
    },
    chartOption4: {
      title: {
        text: '三务管理数据变化图',
        offset: [-150, -18],
        style: {
          fill: '#fff',
          fontSize: 13
        }
      },
      legend: {
        right: '10%',
        top: '20%',
        data: [{
          name: '设备接入',
          color: "#41E4BB"
        }, {
          name: '数据共享',
          color: "#0091FF"
        }],
        textStyle: {
          fontFamily: 'Arial',
          fontSize: 13,
          fill: '#fff'
        }
      },
      grid: {
        left: '15%'
      },
      xAxis: {
        name: '',
        data: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '22:00'],
        axisLine: {
          style: {
            stroke: '#2F626D'
          },
          lineStyle: {
            color: '#333'
          }
        },
        axisTick: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisLabel: {
          style: {
            fill: '#2F626D',
            fontSize: 12
          }
        },
        splitLine: {
          style: {
            stroke: '#2F626D'
          }
        }
      },
      yAxis: {
        name: '',
        data: 'value',
        axisLine: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisTick: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisLabel: {
          style: {
            fill: '#2F626D',
            fontSize: 12
          }
        },
        splitLine: {
          style: {
            stroke: '#2F626D',
            lineDash: [5, 5]
          }
        }
      },
      series: [{
        name: '设备接入',
        data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
        type: 'bar'
      }, {
        name: '数据共享',
        data: [1800, 5822, 3500, 1100, 2000, 2000, 4500],
        type: 'bar'
      }],
      color: ['#41E4BB', '#0091FF']
    },
    chartOption5: {
      data: [{
        name: '家庭农场',
        value: 813
      }, {
        name: '种养大户',
        value: 170
      }, {
        name: ' 合 作 社  ',
        value: 118
      }, {
        name: '龙头企业',
        value: 312
      }],
      showValue: true,
      columnColor: 'rgba(39, 243, 142, 0.4)'
    },
    chartOption: {
      title: {
        text: '周销售额趋势',
        style: {
          fill: '#fff'
        }
      },
      xAxis: {
        name: '',
        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        axisLine: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisTick: {
          style: {
            stroke: '#2F626D'
          }
        },
        axisLabel: {
          style: {
            fill: '#2F626D',
            fontSize: 12
          }
        },
        splitLine: {
          style: {
            stroke: '#2F626D'
          }
        }
      },
      yAxis: {
        name: '销售额',
        data: 'value',
        nameTextStyle: {
          fill: '#fff'
        },
        axisLine: {
          style: {
            stroke: '#fff'
          }
        },
        axisTick: {
          style: {
            stroke: '#fff'
          }
        },
        axisLabel: {
          style: {
            fill: '#fff',
            fontSize: 12
          }
        },
        splitLine: {
          style: {
            stroke: '#fff'
          }
        }
      },
      series: [{
        data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
        type: 'bar',
        gradient: {
          color: ['#37a2da', '#67e0e3']
        }
      }]
    }
  },
  mapLayerTdtOriginal: null,
  mapLayerTdtColorsize: null,
  mapLayerGetTile: null,
  mapInstance: null,
  isMap: false,
  AMap: null,
  isLoading: false,
  dialogTableVisible: false,
  dialogTableVisible1: false
};
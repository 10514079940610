var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "center-body",
      style:
        "background-image: url(" + _vm.siteConfig.showroom_background + ")",
    },
    [
      _c("div", { staticClass: "mn-wrap" }, [
        _c(
          "div",
          {
            staticClass: "swiper-container mn-swiper",
            on: {
              mouseenter: _vm.swiperMouseenterContainer,
              mouseleave: _vm.swiperMouseleaveContainer,
            },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", {
              staticClass: "swiper-button-prev arrow-left",
              on: { click: _vm.swiperArrowLeft },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "swiper-button-next arrow-right",
              on: { click: _vm.swiperArrowRight },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "swiper-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "swiper-slide",
          staticStyle: { "background-color": "#276041" },
        },
        [_vm._v("Slide 1")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "swiper-slide",
          staticStyle: { "background-color": "#40528C" },
        },
        [_vm._v("Slide 2")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "swiper-slide",
          staticStyle: { "background-color": "#A85050" },
        },
        [_vm._v("Slide 3")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "swiper-slide",
          staticStyle: { "background-color": "#3E3190" },
        },
        [_vm._v("Slide 4")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _data = require("@/api/data");
var _productMenu = _interopRequireDefault(require("../components/product-menu.vue"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    productMenu: _productMenu.default
  },
  data: function data() {
    return {
      listPosition: 0,
      scrollTimer: null,
      mySwiper: null,
      menuTopList: [],
      menuList: [],
      menuDataList: [],
      imgShow: false,
      imgUrl: ""
    };
  },
  created: function created() {
    this.$watch("$route.query.type", function (val) {
      var _this = this;
      if (val) {
        var params = {
          type: val
        };
        (0, _request.default)({
          url: "Publicize/index",
          method: "get",
          params: params
        }).then(function (response) {
          _this.menuTopList = response.data.list.slice(0, 6);
          _this.menuList = response.data.list.slice(6, 13);
          _this.setItemListData(response.data.list.slice(13));
          setTimeout(function () {
            _this.mySwiper = new Swiper(".swiper-ww-container", {
              autoplay: true,
              loop: false,
              speed: 20000,
              //匀速时间
              freeMode: true //设置为true则变为free模式
            });
          }, 1000);
        });
      }
    }, {
      immediate: true
    });
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    siteConfig: function siteConfig(state) {
      return state.app.siteConfig;
    }
  })),
  destroyed: function destroyed() {
    clearInterval(this.scrollTimer);
  },
  methods: {
    goUrl: function goUrl(item) {
      if (item.url) {
        window.open(item.url);
      }
    },
    goUrlPath: function goUrlPath(url) {
      this.imgUrl = url;
      this.imgShow = true;
    },
    setItemListData: function setItemListData(menuDataList) {
      var listData = [[]];
      var sIndex = -1;
      for (var index = 0; index < menuDataList.length; index++) {
        if (index % 6 == 0) {
          sIndex++;
          listData[sIndex] = [];
        }
        listData[sIndex].push(menuDataList[index]);
      }
      this.menuDataList = listData;
    },
    //鼠标移入悬停
    swiperMouseenterContainer: function swiperMouseenterContainer() {
      if (this.mySwiper) {
        this.mySwiper.stopAutoplay();
      }
    },
    //鼠标移出滚动
    swiperMouseleaveContainer: function swiperMouseleaveContainer() {
      if (this.mySwiper) {
        this.mySwiper.startAutoplay();
      }
    }
  }
};
"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDataByBooks = getDataByBooks;
exports.getDataByHistory = getDataByHistory;
exports.getDataByNews = getDataByNews;
exports.getDataByProject = getDataByProject;
exports.getDataProjectList = getDataProjectList;
exports.getSiteConfig = getSiteConfig;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取网站配置
function getSiteConfig(params) {
  return (0, _request.default)({
    url: 'index/siteConfig',
    method: 'get',
    params: params
  });
}
//获取项目数据
function getDataByProject(params) {
  return (0, _request.default)({
    url: 'index/index',
    method: 'get',
    params: params
  });
}

//获取分类列表
function getDataProjectList(params) {
  return (0, _request.default)({
    url: 'index/getMenu',
    method: 'get',
    params: params
  });
}
//获取新闻数据
function getDataByNews(params) {
  return (0, _request.default)({
    url: 'news/index',
    method: 'get',
    params: params
  });
}
//获取企业路径数据
function getDataByHistory(params) {
  return (0, _request.default)({
    url: 'history/index',
    method: 'get',
    params: params
  });
}

//获取新闻数据
function getDataByBooks(params) {
  return (0, _request.default)({
    url: 'book/index',
    method: 'get',
    params: params
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("com-header"),
      _vm._v(" "),
      _c("div", { staticClass: "liy-map-container" }, [
        _c("div", {
          staticClass: "map-container",
          attrs: { id: "liy-map-car-container" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "liy-content" }, [
          _c("div", { staticClass: "liy-content-left" }, [
            _c(
              "div",
              { staticClass: "lc-left-top " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c("div", { staticClass: "liy-common-title" }, [
                        _c("div", { staticClass: "lct-left" }, [
                          _vm._v("资源库"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "lcl-party" }, [
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/rich_icon1.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("产品库")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("79,564"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/rich_icon2.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("项目库")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("6,252"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/rich_icon3.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("服务库")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("9,564"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/rich_icon4.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("成果库")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("6,252"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/rich_icon5.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("2,564")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("457人"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "lcl-party-item" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/details_images/rich_icon6.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-party-right" }, [
                            _c(
                              "div",
                              { staticClass: "lcl-party-right-label" },
                              [_vm._v("专家库")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-party-right-num" }, [
                              _vm._v("252"),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-left-center " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c("div", { staticClass: "liy-common-title" }, [
                        _c("div", { staticClass: "lct-left" }, [
                          _vm._v("供需对接"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "lclc-civi" },
                        [
                          _c("dv-charts", {
                            staticStyle: { height: "200px", width: "100%" },
                            attrs: { option: _vm.configData.chartOption6 },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-left-bottom" },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c("div", { staticClass: "liy-common-title" }, [
                        _c("div", { staticClass: "lct-left" }, [
                          _vm._v("金融服务"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "liy-char4" },
                        [
                          _c("dv-charts", {
                            staticStyle: {
                              height: "200px",
                              "font-size": "12px",
                              width: "100%",
                            },
                            attrs: { option: _vm.configData.chartOption8 },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "liy-content-right" }, [
            _c(
              "div",
              { staticClass: "lc-right-top " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c(
                        "div",
                        { staticClass: "liy-common-title liy-common-title-v1" },
                        [
                          _c("div", { staticClass: "lct-left" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lct-right" }, [
                            _c("div", { staticClass: "lct-right-text1" }, [
                              _vm._v("农产交易项目分析"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "liy-chart2" },
                        [
                          _c("dv-charts", {
                            staticStyle: {
                              height: "220px",
                              "font-size": "12px",
                              width: "100%",
                            },
                            attrs: { option: _vm.configData.chartOption2 },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-right-center " },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c(
                        "div",
                        { staticClass: "liy-common-title liy-common-title-v1" },
                        [
                          _c("div", { staticClass: "lct-left" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "lct-right" }, [
                            _c("div", { staticClass: "lct-right-text1" }, [
                              _vm._v("品牌打造"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "liy-chart1" },
                        [
                          _c("dv-scroll-board", {
                            staticStyle: {
                              width: "95%",
                              height: "200px",
                              margin: "0 auto",
                              "margin-top": "10px",
                            },
                            attrs: { config: _vm.configData.tableData },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lc-right-bottom" },
              [
                _c(
                  "dv-border-box-8",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      color: _vm.configData.borderBorder,
                      reverse: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "lct-warp-v1" }, [
                      _c(
                        "div",
                        { staticClass: "liy-common-title liy-common-title-v1" },
                        [
                          _c("div", { staticClass: "lct-left" }, [
                            _c("div", { staticClass: "lct-left-more" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/data_images/liy-img47.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("更多")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lct-right" }, [
                            _c("div", { staticClass: "lct-right-text1" }, [
                              _vm._v("数智赋能"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "liy-char4" }, [
                        _c("div", { staticClass: "lc4-list" }, [
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "http://www.ymxczx.com:18885/index"
                                  )
                                },
                              },
                            },
                            [_vm._v("好品平台")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "http://test.b5net.com/cheguansuo/#/"
                                  )
                                },
                              },
                            },
                            [_vm._v("交易平台")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl("https://lygy.bjedtek.com/")
                                },
                              },
                            },
                            [_vm._v("供应链平台")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "http://wenlv.page.sdmc.vip/#/"
                                  )
                                },
                              },
                            },
                            [_vm._v("文旅平台")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "http://www.ymxczx.com:18885/index"
                                  )
                                },
                              },
                            },
                            [_vm._v("合伙人平台")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lc4-item",
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    "http://test.b5net.com/cheguansuo/#/"
                                  )
                                },
                              },
                            },
                            [_vm._v("金融服务平台")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("com-footer", { attrs: { menuIndex: 7 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
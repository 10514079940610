"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _map = require("@/utils/map");
var _new_xian = _interopRequireDefault(require("@/assets/demo_json/new_xian.json"));
var _data = require("./data");
var _comm_header = _interopRequireDefault(require("./../../components/comm_header.vue"));
var _comm_footer = _interopRequireDefault(require("./../../components/comm_footer.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Index",
  components: {
    comHeader: _comm_header.default,
    comFooter: _comm_footer.default
  },
  data: function data() {
    return _data.vueData;
  },
  created: function created() {
    //加载地图
    this.getMapEventData();
  },
  methods: {
    initDigConfig: function initDigConfig() {
      var _this = this;
      setTimeout(function () {
        _this.configData.digConfig1 = {
          number: [51],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig2 = {
          number: [65131],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig3 = {
          number: [54552],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig4 = {
          number: [3244],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig5 = {
          number: [701],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig6 = {
          number: [30.4],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
      }, 400);
    },
    getMapEventData: function getMapEventData() {
      var _this2 = this;
      if (this.isMap == false) {
        (0, _map.loadMap)(function (AMap) {
          if (window.mapInstance) {
            _this2.destroyMap(window.mapInstance);
          }
          _this2.mapInstance = new AMap.Map("liy-map-car-container", {
            mapStyle: 'amap://styles/blue',
            //设置地图的显示样式
            center: [117.214, 34.9268],
            zooms: [4, 18],
            zoom: 16,
            layers: [new AMap.TileLayer.Satellite()]
          });
          _this2.mapLayerTdtOriginal = new AMap.TileLayer.WMTS({
            // url: "http://huanweitdt.sdlcsg.com",
            url: 'https://t0.tianditu.gov.cn/img_w/wmts',
            blend: false,
            tileSize: 256,
            params: {
              Layer: "img",
              Version: "1.0.0",
              Format: "tiles",
              TileMatrixSet: "w",
              STYLE: "default",
              tk: "257492e7b6e7eeebc2ac14b8f2dc3e13",
              mode: "original"
            }
          });
          _this2.mapLayerTdtColorsize = new AMap.TileLayer.WMTS({
            // url: "http://huanweitdt.sdlcsg.com",
            url: 'https://t0.tianditu.gov.cn/img_w/wmts',
            blend: false,
            tileSize: 256,
            params: {
              Layer: "img",
              Version: "1.0.0",
              Format: "tiles",
              TileMatrixSet: "w",
              STYLE: "default",
              tk: "257492e7b6e7eeebc2ac14b8f2dc3e13",
              mode: "colorsize"
            }
          });
          _this2.mapLayerGetTile = new AMap.TileLayer.WMTS({
            url: "http://t4.tianditu.gov.cn/cia_w/wmts",
            blend: false,
            tileSize: 256,
            params: {
              Layer: "cia",
              Version: "1.0.0",
              Format: "tiles",
              TileMatrixSet: "w",
              STYLE: "default",
              tk: "257492e7b6e7eeebc2ac14b8f2dc3e13",
              mode: "original",
              Request: 'GetTile',
              Service: 'WMTS'
            }
          });
          window.mapInstance = _this2.mapInstance;
          _this2.isMap = true;
          _this2.AMap = AMap;
          _this2.createBundleIconMarkers();
          //加载城市边框线
          _this2.createGeoJsonLine();
          _this2.updateMapLayers();

          // 地图加载完成
          _this2.mapInstance.on('complete', function (res) {
            _this2.mapInstanceComplete();
          });
        });
      }
    },
    mapInstanceComplete: function mapInstanceComplete() {
      var _this3 = this;
      setTimeout(function () {
        _this3.isLoading = true;
        //加载翻牌器
        _this3.initDigConfig();
      }, 400);
    },
    createGeoJsonLine: function createGeoJsonLine() {
      var geojson = new AMap.GeoJSON({
        geoJSON: _new_xian.default,
        // 还可以自定义getMarker和getPolyline
        getPolygon: function getPolygon(geojson, lnglats) {
          // 计算面积
          return new AMap.Polygon({
            path: lnglats,
            fillOpacity: 0.4,
            // 面积越大透明度越高
            strokeColor: '#27F38E',
            fillColor: 'green'
          });
        }
      });
      this.mapInstance.add(geojson);
    },
    //在指定位置打开信息窗体
    openInfo: function openInfo(position, extData) {
      //构建信息窗体中显示的内容
      var info = [];
      // <img style=\"float:left;width:67px;height:16px;\" src=\" https://webapi.amap.com/images/autonavi.png \"/>
      info.push("<div > ");
      info.push("<div style=\"padding:7px 0px 0px 0px;\"><h4>" + extData.name + "</h4>");
      info.push("<p style=\"font-size:13px;\">" + extData.desc + "</p>");
      info.push("<p style=\"font-size:13px;\">地址 :" + extData.address + "</p></div></div>");
      var infoWindow = new AMap.InfoWindow({
        content: info.join("") //使用默认信息窗体框样式，显示信息内容
      });
      infoWindow.open(this.mapInstance, position);
    },
    //更新天地图地图瓦片
    updateMapLayers: function updateMapLayers() {
      if (this.useColorsizeLayer) {
        this.mapInstance.removeLayer(this.mapLayerTdtOriginal);
        this.mapInstance.addLayer(this.mapLayerTdtColorsize);
      } else {
        this.mapInstance.removeLayer(this.mapLayerTdtColorsize);
        this.mapInstance.addLayer(this.mapLayerTdtOriginal);
      }
      this.mapInstance.addLayer(this.mapLayerGetTile);
    },
    //加载所有的地图标点
    createBundleIconMarkers: function createBundleIconMarkers() {
      var that = this;
      var colorLegend = {
        // 10: '#fde7a9',
        10: '#f9c02f',
        100: '#f5a54f',
        500: '#f18334',
        1000: '#cc5f42',
        5000: '#a94d36',
        100000: '#792a17'
      };
      var textStyle = {
        // 字体大小
        fontSize: 12,
        // 字体颜色
        fillColor: '#fff',
        padding: '2, 5',
        backgroundColor: 'rgb(246,137,38)',
        borderColor: '#fff',
        borderWidth: 1
      };
      var LabelsData = [{
        name: '自提点4',
        position: [117.213147, 34.925735],
        zooms: [10, 20],
        anchor: 'center',
        // 设置文本标记锚点
        draggable: true,
        cursor: 'pointer',
        opacity: 1,
        zIndex: 23,
        extData: {
          name: '官桥医院',
          desc: '电话 : 010-xxx0400 邮编 : 100xx2',
          address: '礼贤路旁边, 靠近514省道--礼贤路路口'
        },
        text: {
          content: '官桥医院',
          direction: 'right',
          offset: [-20, -5],
          style: textStyle
        }
      }, {
        name: '自提点5',
        position: [117.217306, 34.930774],
        zooms: [10, 20],
        opacity: 1,
        anchor: 'center',
        // 设置文本标记锚点
        draggable: true,
        cursor: 'pointer',
        zIndex: 6,
        extData: {
          name: '官桥四村村委员会',
          desc: '电话 : 010-xxx0400 邮编 : 100xx2',
          address: '在官桥镇人民政府附近, 在礼贤路旁边, 靠近514省道--礼贤路路口'
        },
        text: {
          content: '官桥四村村委员会',
          direction: 'right',
          offset: [-20, -5],
          style: textStyle
        }
      }, {
        name: '自提点5',
        position: [117.202677, 34.928673],
        zooms: [10, 20],
        anchor: 'center',
        // 设置文本标记锚点
        draggable: true,
        cursor: 'pointer',
        opacity: 1,
        zIndex: 6,
        extData: {
          name: '官桥镇人民政府',
          desc: '电话 : 010-xxx0400 邮编 : 100xx2',
          address: '在山东滕州农村商业银行(官桥支行)附近, 在礼贤路旁边, 靠近514省道--礼贤路路口'
        },
        text: {
          content: '官桥镇人民政府',
          direction: 'right',
          offset: [-20, -5],
          style: textStyle
        }
      }, {
        name: '自提点5',
        position: [117.202677, 34.928673],
        zooms: [10, 20],
        anchor: 'center',
        // 设置文本标记锚点
        draggable: true,
        cursor: 'pointer',
        opacity: 1,
        zIndex: 6,
        extData: {
          name: '滕州市第五中学',
          desc: '电话 : 010-xxx0400 邮编 : 100xx2',
          address: '在礼贤路旁边, 靠近益康大道--322省道路口'
        },
        text: {
          content: '滕州市第五中学',
          direction: 'right',
          offset: [-20, -5],
          style: textStyle
        }
      }, {
        name: '自提点5',
        position: [117.211901, 34.920805],
        zooms: [10, 20],
        anchor: 'center',
        // 设置文本标记锚点
        draggable: true,
        cursor: 'pointer',
        opacity: 1,
        extData: {
          name: '薛国文化公园',
          desc: '电话 : 010-xxx0400 邮编 : 100xx2',
          address: '在官桥汉代遗址博物馆附近, 在外环路旁边, 靠近纳贤路--322省道路口'
        },
        zIndex: 6,
        text: {
          content: '薛国文化公园',
          direction: 'right',
          offset: [-20, -5],
          style: textStyle
        }
      }];
      var markers = [];
      var allowCollision = false;
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        // collision: false,
        // 设置 allowCollision：true，可以让标注避让用户的标注
        allowCollision: allowCollision
      });
      layer.add(markers);
      // 图层添加到地图
      this.mapInstance.add(layer);

      // 初始化 labelMarker
      for (var i = 0; i < LabelsData.length; i++) {
        var curData = LabelsData[i];
        var labelMarker = new AMap.LabelMarker(curData);
        markers.push(labelMarker);
        // 给marker绑定事件
        labelMarker.on('click', function (e) {
          var position = e.data.data && e.data.data.position;
          that.openInfo(position, this.getExtData());
        });
      }

      // 将 marker 添加到图层
      layer.add(markers);
    },
    //加载标点
    addIconByMarker: function addIconByMarker(url, lng, lat) {
      // 创建一个 Icon
      var startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(100, 100),
        // 图标的取图地址
        image: url,
        // 图标所用图片大小
        imageSize: new AMap.Size(100, 100),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(0, 0)
      });

      // 将 icon 传入 marker
      return new AMap.Marker({
        position: new AMap.LngLat(lng, lat),
        icon: startIcon,
        offset: new AMap.Pixel(-13, -30)
      });
    },
    goToPages: function goToPages(url) {
      this.$router.push({
        path: url
      });
    },
    goUrl: function goUrl(url) {
      window.open(url);
    },
    destroyMap: function destroyMap(map) {
      if (map) {
        map.destroy();
        window.mapInstance = null;
        window.mapInstanceWWE = null;
      }
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listFrame: [{
        src: "/#/"
      }, {
        src: "/#/center"
      }, {
        src: _settings.baseApi + "/PDygvSJkIC.php/mac.index.index/index"
      }, {
        src: "//vr.3d66.com/vr/index_detail_4304506.asp"
      }],
      clickTimes: 0,
      lastClickIndex: null
    };
  },
  methods: {
    onFrameClick: function onFrameClick(item, index) {
      if (this.lastClickIndex !== index) {
        this.clickTimes = 0;
      }
      this.lastClickIndex = index;
      this.clickTimes++;
      if (this.clickTimes >= 4) {
        this.clickTimes = 0;
        location.href = item.src;
      }
    }
  }
};
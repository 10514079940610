"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _map = require("@/utils/map");
var _comm_header = _interopRequireDefault(require("./../components/comm_header.vue"));
var _comm_footer = _interopRequireDefault(require("./../components/comm_footer.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Index",
  components: {
    comHeader: _comm_header.default,
    comFooter: _comm_footer.default
  },
  data: function data() {
    return {
      configData: {
        borderBorder: ['#095A3E', '#27F38E'],
        digConfig1: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig2: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig3: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig4: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig5: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        digConfig6: {
          number: [0],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        },
        chartOption1: {
          title: {
            text: '三务管理数据变化图',
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '10%',
            top: '20%',
            data: [{
              name: '村务',
              color: "#41E4BB"
            }, {
              name: '党务',
              color: "#E44141"
            }, {
              name: '财务',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '村务',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '党务',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '财务',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }],
          color: ['#41E4BB', '#E44141', '#0091FF']
        },
        chartOption2: {
          textStyle: {
            color: '#fff'
          },
          style: {
            fill: '#fff',
            fontSize: 13
          },
          title: {
            text: '周销售额趋势',
            show: false
          },
          legend: {
            data: ['需求项目', '供应项目', '成交项目'],
            color: '#fff',
            style: {
              color: '#fff'
            },
            textStyle: {
              fill: '#fff'
            },
            right: '10%',
            top: '20%'
          },
          xAxis: {
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLabel: {
              style: {
                fill: '#E6F7FF'
              }
            }
          },
          yAxis: {
            // name: '项目分析',
            data: 'value',
            axisLabel: {
              style: {
                fill: '#E6F7FF'
              }
            }
          },
          series: [{
            name: '需求项目',
            data: [317, 323, 185, 198, 205, 289, 217],
            type: 'line',
            fill: {
              show: true
            },
            color: '#41E4BB'
          }, {
            name: '供应项目',
            data: [178, 199, 209, 213, 166, 173, 229],
            type: 'line',
            smooth: true,
            color: '#E44141'
          }, {
            name: '成交项目',
            data: [330, 301, 217, 269, 173, 105, 208],
            type: 'line',
            color: '#0091FF'
          }]
        },
        chartOption3: {
          title: {
            text: '产权交易项目分析',
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '2%',
            top: '16%',
            data: [{
              name: '需求项目',
              color: "#41E4BB"
            }, {
              name: '供应项目',
              color: "#E44141"
            }, {
              name: '成交项目',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '需求项目',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '供应项目',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }, {
            name: '成交项目',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }],
          color: ['#41E4BB', '#E44141', '#0091FF']
        },
        chartOption4: {
          title: {
            text: '三务管理数据变化图',
            offset: [-150, -18],
            style: {
              fill: '#fff',
              fontSize: 13
            }
          },
          legend: {
            right: '10%',
            top: '20%',
            data: [{
              name: '设备接入',
              color: "#41E4BB"
            }, {
              name: '数据共享',
              color: "#0091FF"
            }],
            textStyle: {
              fontFamily: 'Arial',
              fontSize: 13,
              fill: '#fff'
            }
          },
          grid: {
            left: '15%'
          },
          xAxis: {
            name: '',
            data: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '22:00'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              },
              lineStyle: {
                color: '#333'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '',
            data: 'value',
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D',
                lineDash: [5, 5]
              }
            }
          },
          series: [{
            name: '设备接入',
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar'
          }, {
            name: '数据共享',
            data: [1800, 5822, 3500, 1100, 2000, 2000, 4500],
            type: 'bar'
          }],
          color: ['#41E4BB', '#0091FF']
        },
        chartOption5: {
          data: [{
            name: '家庭农场',
            value: 55
          }, {
            name: '种养大户',
            value: 120
          }, {
            name: ' 合 作 社  ',
            value: 71
          }, {
            name: '龙头企业',
            value: 66
          }],
          showValue: true,
          columnColor: 'rgba(39, 243, 142, 0.4)'
        },
        chartOption6: {
          title: {
            text: '周销售额趋势',
            show: false
          },
          legend: {
            data: ['出口数量', '进口数量'],
            right: '10%',
            top: '20%',
            textStyle: {
              fill: '#fff'
            }
          },
          xAxis: {
            // name: '第一周',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisLabel: {
              style: {
                fill: '#E6F7FF'
              }
            }
          },
          yAxis: {
            // name: '销售额',
            data: 'value',
            axisLabel: {
              style: {
                fill: '#E6F7FF'
              }
            }
          },
          series: [{
            name: '出口数量',
            data: [225, 217, 258, 82, 283, 179, 321],
            type: 'line',
            fill: {
              show: true
            },
            color: '#41E4BB'
          }, {
            name: '进口数量',
            data: [174, 105, 132, 171, 219, 295, 175],
            type: 'line',
            smooth: true,
            color: '#0091FF'
          }]
        },
        chartOption8: {
          title: {
            text: '周销售额趋势',
            style: {
              color: '#fff'
            },
            show: false
          },
          xAxis: {
            data: ['银农直连', '普惠金融', '银农直连', '普惠金融', '银农直连', '普惠金融'],
            axisLabel: {
              style: {
                fill: '#E6F7FF'
              }
            }
          },
          yAxis: {
            name: '金融扶持额度（万元）',
            data: 'value',
            nameTextStyle: {
              fill: '#E6F7FF',
              fontSize: 10
            },
            axisLabel: {
              style: {
                fill: '#E6F7FF'
              }
            }
          },
          legend: {
            data: ['2022年', '2023年'],
            right: '10%',
            top: '20%',
            textStyle: {
              fill: '#fff'
            }
          },
          series: [{
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar',
            name: '2022年',
            color: '#41E4BB'
          }, {
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar',
            name: '2023年',
            color: '#0091FF'
          }]
        },
        chartOption: {
          title: {
            text: '周销售额趋势',
            style: {
              fill: '#fff'
            }
          },
          xAxis: {
            name: '',
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            axisLine: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisTick: {
              style: {
                stroke: '#2F626D'
              }
            },
            axisLabel: {
              style: {
                fill: '#2F626D',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#2F626D'
              }
            }
          },
          yAxis: {
            name: '销售额',
            data: 'value',
            nameTextStyle: {
              fill: '#fff'
            },
            axisLine: {
              style: {
                stroke: '#fff'
              }
            },
            axisTick: {
              style: {
                stroke: '#fff'
              }
            },
            axisLabel: {
              style: {
                fill: '#fff',
                fontSize: 12
              }
            },
            splitLine: {
              style: {
                stroke: '#fff'
              }
            }
          },
          series: [{
            data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
            type: 'bar',
            gradient: {
              color: ['#37a2da', '#67e0e3']
            }
          }]
        },
        tableData: {
          header: ['机构', '项目名称', '开始时间'],
          headerBGC: 'rgba(39,243,142,0.1)',
          oddRowBGC: 'rgba(0,0,0,0.01)',
          evenRowBGC: 'rgba(0,0,0,0.01)',
          data: [['水果', '猕猴桃逃逃逃', '2023.05.18'], ['蔬菜', '白菜', '2023.05.18'], ['肉蛋‍类', '鸭蛋', '2023.05.18'], ['副食加工', '牛奶', '2023.05.18'], ['副食加工', '果汁', '2023.05.18'], ['水果', '苹果', '2023.05.18'], ['水果', '梨子', '2023.05.18']]
        }
      },
      mapInstance: null,
      isMap: false,
      AMap: null
    };
  },
  created: function created() {
    //加载地图
    this.getMapEventData();
    //加载翻牌器
    this.initDigConfig();
  },
  methods: {
    initDigConfig: function initDigConfig() {
      var _this = this;
      setTimeout(function () {
        _this.configData.digConfig1 = {
          number: [999],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig2 = {
          number: [7149],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig3 = {
          number: [48416],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig4 = {
          number: [16579],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig5 = {
          number: [51546],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
        _this.configData.digConfig6 = {
          number: [23344],
          style: {
            fill: '#27F38E'
          },
          fontSize: 24
        };
      }, 400);
    },
    getMapEventData: function getMapEventData() {
      var _this2 = this;
      if (this.isMap == false) {
        (0, _map.loadMap)(function (AMap) {
          if (window.mapInstance) {
            _this2.destroyMap(window.mapInstance);
          }
          _this2.mapInstance = new AMap.Map("liy-map-car-container", {
            mapStyle: 'amap://styles/blue',
            //设置地图的显示样式
            center: [117.214, 34.9268],
            zoom: 16,
            features: ['bg', 'road'],
            resizeEnable: true
          });
          window.mapInstance = _this2.mapInstance;
          _this2.isMap = true;
          _this2.AMap = AMap;
          // 地图加载完成
          _this2.mapInstance.on('complete', function (res) {});
        });
      }
    },
    goUrl: function goUrl(url) {
      window.open(url);
    },
    goToPages: function goToPages(url) {
      this.$router.push({
        path: url
      });
    },
    destroyMap: function destroyMap(map) {
      if (map) {
        map.destroy();
        window.mapInstance = null;
        window.mapInstanceWWE = null;
      }
    }
  }
};
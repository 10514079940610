"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    menuType: {
      type: String,
      default: "all"
    }
  },
  data: function data() {
    return {
      year: "",
      month: "",
      day: "",
      hour: "",
      minute: "",
      second: "",
      week: "",
      stop: null
    };
  },
  methods: {
    onHeaderLeftClick: function onHeaderLeftClick() {
      this.clickTimes++;
      if (this.clickTimes >= 4) {
        location.href = "/#/iframes";
      }
    }
  },
  created: function created() {
    var _this = this;
    this.stop = setInterval(function () {
      var now = new Date();
      _this.year = now.getFullYear();
      _this.month = now.getMonth() + 1; // 月份从0开始，所以要加1
      _this.day = now.getDate();
      _this.hour = now.getHours();
      _this.minute = now.getMinutes();
      _this.second = now.getSeconds();
      var week = ["日", "一", "二", "三", "四", "五", "六"];
      _this.week = week[now.getDay()];
    }, 1000);
  },
  destroyed: function destroyed() {
    clearInterval(this.stop);
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "liy-footer" }, [
      _c("div", { staticClass: "liy-footer-menu" }, [
        _c(
          "div",
          {
            staticClass: "liy-footer-item",
            class: _vm.menuIndex == 1 ? "activate" : "",
          },
          [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToPages("/", 1)
                  },
                },
              },
              [_vm._v("官桥纵览")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "liy-footer-item",
            class: _vm.menuIndex == 2 ? "activate" : "",
          },
          [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToPages("/tourism_details", 2)
                  },
                },
              },
              [_vm._v("文明党建")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "liy-footer-item",
            class: _vm.menuIndex == 3 ? "activate" : "",
          },
          [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToPages("/index1", 3)
                  },
                },
              },
              [_vm._v("乡村治理")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "liy-footer-item",
            class: _vm.menuIndex == 4 ? "activate" : "",
          },
          [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToPages("/tourism_list", 4)
                  },
                },
              },
              [_vm._v("生态宜居")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "liy-footer-item",
            class: _vm.menuIndex == 6 ? "activate" : "",
          },
          [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToPages("/index2", 6)
                  },
                },
              },
              [_vm._v("产业兴旺")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "liy-footer-item",
            class: _vm.menuIndex == 7 ? "activate" : "",
          },
          [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.goToPages("/index3", 7)
                  },
                },
              },
              [_vm._v("共同富裕")]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.destroyMap = destroyMap;
exports.getPolygonArrayUtils = getPolygonArrayUtils;
exports.getPolygonUtils = getPolygonUtils;
exports.loadMap = loadMap;
exports.wgs84Togcj02 = wgs84Togcj02;
var _amapJsapiLoader = _interopRequireDefault(require("@amap/amap-jsapi-loader"));
var mapKey = "90dd8f831e4e914f5c4fcf2cb88e19d9";
function loadMap(onSuccess) {
  if (!onSuccess) {
    onSuccess = function onSuccess() {};
  }
  _amapJsapiLoader.default.load({
    "key": mapKey,
    // 申请好的Web端开发者Key，首次调用 load 时必填
    "version": "2.0",
    // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    "plugins": ['AMap.MapType', 'AMap.Scale', 'AMap.HawkEye', 'AMap.ToolBar', 'AMap.GeoJSON', 'AMap.ControlBar', 'AMap.MouseTool', 'AMap.PolygonEditor', 'AMap.MarkerCluster', 'AMap.MoveAnimation', "AMap.AutoComplete", "AMap.PlaceSearch", 'AMap.ControlBar,AMap.ToolBar', "AMap.Geocoder"] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  }).then(onSuccess).catch(function (e) {
    console.log(e);
  });
}
function getPolygonUtils(polygon) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var polygonArray = JSON.parse(polygon);
  var itemPolygon = [];
  var is_center = false;
  var centerPostion = {};
  for (var key in polygonArray) {
    if (polygonArray[key].length == 2) {
      if (type == 1) {
        var node = gcjToWgs(parseFloat(polygonArray[key][0]), parseFloat(polygonArray[key][1]));
      } else if (type == 3) {
        var node = wgs84Togcj02(parseFloat(polygonArray[key][0]), parseFloat(polygonArray[key][1]));
      } else {
        var node = [parseFloat(polygonArray[key][0]), parseFloat(polygonArray[key][1])];
      }
      itemPolygon.push([node[0], node[1]]);
      if (is_center == false) {
        centerPostion = {
          map_lng: polygonArray[key][0],
          map_lat: polygonArray[key][1]
        };
        is_center = true;
      }
    }
  }
  return {
    itemPolygon: itemPolygon,
    centerPostion: centerPostion
  };
}

// 批量处理geojson 偏移位置
function getPolygonArrayUtils(polygon) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var itemPolygon = [];
  for (var i = 1; i < polygon.length; i++) {
    if (polygon[i].length == 2) {
      if (type == 1) {
        var node = gcjToWgs(parseFloat(polygon[i][0]), parseFloat(polygon[i][1]));
      } else if (type == 3) {
        var node = wgs84Togcj02(parseFloat(polygon[i][0]), parseFloat(polygon[i][1]));
      } else {
        var node = [parseFloat(polygon[i][0]), parseFloat(polygon[i][1])];
      }
      itemPolygon.push([node[0], node[1]]);
    }
  }
  return itemPolygon;
}
function gcjToWgs(lon, lat) {
  var PI = 3.14159265358979324;
  function transformGCJ2WGS(gcjLon, gcjLat) {
    var d = delta(gcjLon, gcjLat);
    return [gcjLon - d.lon, gcjLat - d.lat];
  }
  function delta(x, y) {
    var a = 6378245.0; //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
    var ee = 0.00669342162296594323; //  ee: 椭球的偏心率。
    var dLat = transformLat(x - 105.0, y - 35.0);
    var dLon = transformLon(x - 105.0, y - 35.0);
    var radLat = y / 180.0 * PI;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    dLat = dLat * 180.0 / (a * (1 - ee) / (magic * sqrtMagic) * PI);
    dLon = dLon * 180.0 / (a / sqrtMagic * Math.cos(radLat) * PI);
    return {
      'lat': dLat,
      'lon': dLon
    };
  }
  function transformLat(x, y) {
    var ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
    ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(y * PI) + 40.0 * Math.sin(y / 3.0 * PI)) * 2.0 / 3.0;
    ret += (160.0 * Math.sin(y / 12.0 * PI) + 320 * Math.sin(y * PI / 30.0)) * 2.0 / 3.0;
    return ret;
  }
  function transformLon(x, y) {
    var ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
    ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(x * PI) + 40.0 * Math.sin(x / 3.0 * PI)) * 2.0 / 3.0;
    ret += (150.0 * Math.sin(x / 12.0 * PI) + 300.0 * Math.sin(x / 30.0 * PI)) * 2.0 / 3.0;
    return ret;
  }
  return transformGCJ2WGS(lon, lat);
}
function destroyMap(map) {
  if (map) {
    map.destroy();
    window.mapInstance = null;
    window.mapInstanceWWE = null;
  }
}
function wgs84Togcj02(lng, lat) {
  if (out_of_china(lng, lat)) {
    return [lng, lat];
  }
  //定义一些常量
  //GCJ02 转换为 WGS84
  var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
  var PI = 3.1415926535897932384626;
  var a = 6378245.0;
  var ee = 0.00669342162296594323;
  var dlat = transformlat(lng - 105.0, lat - 35.0);
  var dlng = transformlng(lng - 105.0, lat - 35.0);
  var radlat = lat / 180.0 * PI;
  var magic = Math.sin(radlat);
  magic = 1 - ee * magic * magic;
  var sqrtmagic = Math.sqrt(magic);
  dlat = dlat * 180.0 / (a * (1 - ee) / (magic * sqrtmagic) * PI);
  dlng = dlng * 180.0 / (a / sqrtmagic * Math.cos(radlat) * PI);
  var mglat = lat + dlat;
  var mglng = lng + dlng;
  return [mglng, mglat];
  //return [lng * 2 - mglng, lat * 2 - mglat]
}
function out_of_china(lng, lat) {
  return lng < 72.004 || lng > 137.8347 || lat < 0.8293 || lat > 55.8271 || false;
}
function transformlat(lng, lat) {
  var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
  var PI = 3.1415926535897932384626;
  var a = 6378245.0;
  var ee = 0.00669342162296594323;
  var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
  ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
  ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0;
  ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0;
  return ret;
}
function transformlng(lng, lat) {
  var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
  var PI = 3.1415926535897932384626;
  var a = 6378245.0;
  var ee = 0.00669342162296594323;
  var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
  ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
  ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0;
  ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0;
  return ret;
}
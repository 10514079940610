"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _data = require("@/api/data");
var _productMenu = _interopRequireDefault(require("./../components/product-menu.vue"));
var _settings = require("@/settings");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    productMenu: _productMenu.default
  },
  data: function data() {
    return {
      listPosition: 0,
      scrollTimer: null,
      menuTopList: [],
      menuList: [],
      bookList: [],
      menuDataList: [],
      product_type_id: 0,
      is_show: false,
      baseApi: _settings.baseApi
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    siteConfig: function siteConfig(state) {
      return state.app.siteConfig;
    }
  })),
  mounted: function mounted() {
    this.init();
  },
  destroyed: function destroyed() {
    clearInterval(this.scrollTimer);
  },
  methods: {
    openvr: function openvr() {},
    gotoList: function gotoList() {
      location.href = _settings.baseApi + "/index/book/lst";
    },
    init: function init() {
      this.getDataByBooks();
    },
    // 启动轮播图
    startSwiper: function startSwiper() {
      var _this = this;
      setTimeout(function (_) {
        var swiper = new Swiper(".swiper-container", {
          watchSlidesProgress: true,
          slidesPerView: "auto",
          centeredSlides: true,
          loop: true,
          loopedSlides: 5,
          autoplay: 3000,
          prevButton: ".swiper-button-prev",
          nextButton: ".swiper-button-next",
          pagination: ".swiper-pagination",
          onProgress: function onProgress(swiper, progress) {
            for (var i = 0; i < swiper.slides.length; i++) {
              var slide = swiper.slides.eq(i);
              var slideProgress = swiper.slides[i].progress;
              var modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
              }
              var translate = slideProgress * modify * 260 + "px";
              var scale = 1 - Math.abs(slideProgress) / 5;
              var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform("translateX(" + translate + ") scale(" + scale + ")");
              slide.css("zIndex", zIndex);
              slide.css("opacity", 1);
              if (Math.abs(slideProgress) > 3) {
                slide.css("opacity", 0);
              }
            }
          },
          onSetTransition: function onSetTransition(swiper, transition) {
            for (var i = 0; i < swiper.slides.length; i++) {
              var slide = swiper.slides.eq(i);
              slide.transition(transition);
            }
          }
          // //处理分页器bug
          // onSlideChangeStart: function (swiper) {
          //   if (swiper.activeIndex == 4) {
          //     swiper.bullets.eq(4).addClass("swiper-pagination-bullet-active");
          //   }
          // },
        });
        swiper.on("doubleTap", function (item) {
          var index = item.clickedSlide.dataset.index;
          var book = _this.bookList[index];
          _this.goUrl(book.identifying);
          return false;
        });
        _this.is_show = true;
      }, 188);
    },
    // 获取数据
    getDataByBooks: function getDataByBooks() {
      var _this2 = this;
      (0, _data.getDataByBooks)({}).then(function (response) {
        _this2.bookList = response.data;
        _this2.$nextTick(function () {
          _this2.startSwiper();
        });
      });
    },
    goUrl: function goUrl(name) {
      console.log(name);
      window.open("http://book.wulian.site.sdlcsg.com/?name=" + name);
    }
  }
};
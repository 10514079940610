var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading == false
        ? _c(
            "dv-full-screen-container",
            { staticClass: "liy-loading" },
            [
              _c("dv-loading", { staticStyle: { color: "#fff" } }, [
                _vm._v("加载中..."),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("com-header"),
          _vm._v(" "),
          _c("div", { staticClass: "liy-map-container" }, [
            _c("div", {
              staticClass: "map-container",
              attrs: { id: "liy-map-car-container" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "liy-content" }, [
              _c("div", { staticClass: "liy-content-left" }, [
                _c(
                  "div",
                  { staticClass: "lc-left-top " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c("div", { staticClass: "liy-common-title" }, [
                            _c("div", { staticClass: "lct-left" }, [
                              _vm._v("年龄分布"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lcl-area" }, [
                            _c("div", { staticClass: "lcl-area-item" }, [
                              _c("div", { staticClass: "lcla-content" }, [
                                _c("div", { staticClass: "lcla-title" }, [
                                  _vm._v("行政面积"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "lcla-price" }, [
                                  _c("div", { staticClass: "text-green" }, [
                                    _vm._v("626464"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-unit" }, [
                                    _vm._v("㎡"),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lcl-area-item" }, [
                              _c("div", { staticClass: "lcla-content" }, [
                                _c("div", { staticClass: "lcla-title" }, [
                                  _vm._v("文化景区"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "lcla-price" }, [
                                  _c("div", { staticClass: "text-green" }, [
                                    _vm._v("132"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-unit" }, [
                                    _vm._v("个"),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-chart2" }, [
                            _c("div", { staticClass: "liy-chart2-content" }, [
                              _c(
                                "div",
                                { staticClass: "liy-char2-left" },
                                [
                                  _c("dv-active-ring-chart", {
                                    staticStyle: {
                                      height: "110px",
                                      "font-size": "12px",
                                    },
                                    attrs: {
                                      config: _vm.configData.chartOption2,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "liy-char2-right" }, [
                                _c("div", { staticClass: "liy-char2-list" }, [
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("28岁以下"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("10.28%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("29-35岁"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("26.50%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("36-50岁"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("20.50%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color4" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("51-60岁"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color4" }, [
                                      _vm._v("10.28%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color5" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("61岁及以上"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color5" }, [
                                      _vm._v("10.28%"),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-left-bottom" },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c("div", { staticClass: "liy-common-title" }, [
                            _c("div", { staticClass: "lct-left" }, [
                              _vm._v("村务统计"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lct-right" }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-chart2" }, [
                            _c("div", { staticClass: "liy-chart2-data" }, [
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img53.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("住户人数")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("79,564")]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img57.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("退休人数")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("6,252,22")]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img60.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("农村数量")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("150")]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img56.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("农业用地面积")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("7,222,656")]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img54.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("卫生院机构")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("79,564")]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img59.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("通电乡数")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("6,252,22")]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img62.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("中、小学校数量")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("150")]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "lc2d-item" }, [
                                _c("img", {
                                  staticClass: "lc2d-item-img",
                                  attrs: {
                                    src: require("@/assets/data_images/liy-img58.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "lc2d-item-div" }, [
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text1" },
                                    [_vm._v("数据流通")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "lc2d-item-text2" },
                                    [_vm._v("7,222,656")]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "liy-content-center" }),
              _vm._v(" "),
              _c("div", { staticClass: "liy-content-right" }, [
                _c(
                  "div",
                  { staticClass: "lc-right-top " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "liy-common-title liy-common-title-v1",
                            },
                            [
                              _c("div", { staticClass: "lct-left" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lct-right" }, [
                                _c("div", { staticClass: "lct-right-text1" }, [
                                  _vm._v("阳光村务管理数据"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "230px" },
                                attrs: { option: _vm.configData.chartOption1 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-right-center " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "liy-common-title liy-common-title-v1",
                            },
                            [
                              _c("div", { staticClass: "lct-left" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lct-right" }, [
                                _c("div", { staticClass: "lct-right-text1" }, [
                                  _vm._v("美好乡村建设"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "230px" },
                                attrs: { option: _vm.configData.chartOption6 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-right-bottom" },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "liy-common-title liy-common-title-v1",
                            },
                            [
                              _c("div", { staticClass: "lct-left" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lct-right" }, [
                                _c("div", { staticClass: "lct-right-text1" }, [
                                  _vm._v("三资管理统计"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "230px" },
                                attrs: { option: _vm.configData.chartOption4 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("com-footer", { attrs: { menuIndex: 3 } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
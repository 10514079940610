"use strict";

var _interopRequireDefault = require("/builds/lc-show-project/lc-online-showroom/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _ruoyi = require("@/utils/ruoyi");
var _settings = require("@/settings");
_axios.default.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
var service = _axios.default.create({
  baseURL: _settings.baseApi + '/api/',
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 100, it is judged as an error.
  if (res.code !== 0) {
    (0, _elementUi.Message)({
      message: res.msg || 'Error check your token or method',
      type: 'error',
      duration: 2 * 1000
    });
    return Promise.reject(new Error(res.msg || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 2 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service.request;
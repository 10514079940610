var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading == false
        ? _c(
            "dv-full-screen-container",
            { staticClass: "liy-loading" },
            [
              _c("dv-loading", { staticStyle: { color: "#fff" } }, [
                _vm._v("加载中..."),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("com-header"),
          _vm._v(" "),
          _c("div", { staticClass: "liy-map-container" }, [
            _c("div", {
              staticClass: "map-container",
              attrs: { id: "liy-map-car-container" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "liy-content" }, [
              _c("div", { staticClass: "liy-content-left" }, [
                _c(
                  "div",
                  { staticClass: "lc-left-top " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c("div", { staticClass: "liy-common-title" }, [
                            _c("div", { staticClass: "lct-left" }, [
                              _vm._v("产业统计"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lct-right" }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "liy-chart2" }, [
                            _c("div", { staticClass: "liy-chart2-content" }, [
                              _c(
                                "div",
                                { staticClass: "liy-char2-left" },
                                [
                                  _c("dv-active-ring-chart", {
                                    staticStyle: {
                                      height: "130px",
                                      "font-size": "12px",
                                    },
                                    attrs: {
                                      config: _vm.configData.chartOption2,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "liy-char2-right" }, [
                                _c("div", { staticClass: "liy-char2-list" }, [
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("第一产业"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("2651人"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color1" }, [
                                      _vm._v("10.28%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("第二产业"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("1654人"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color2" }, [
                                      _vm._v("26.50%"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "liy-char2-li" }, [
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("●"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-text2" }, [
                                      _vm._v("第三产业"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("965人"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "lc2l-color3" }, [
                                      _vm._v("20.50%"),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-left-center " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c("div", { staticClass: "liy-common-title" }, [
                            _c("div", { staticClass: "lct-left" }, [
                              _vm._v("优势产业统计"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lct-right" }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "230px" },
                                attrs: { option: _vm.configData.chartOption4 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-left-bottom" },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c("div", { staticClass: "liy-common-title" }, [
                            _c("div", { staticClass: "lct-left" }, [
                              _vm._v("智慧农业项目"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "200px" },
                                attrs: { option: _vm.configData.chartOption1 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "liy-content-center" }),
              _vm._v(" "),
              _c("div", { staticClass: "liy-content-right" }, [
                _c(
                  "div",
                  { staticClass: "lc-right-top " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "liy-common-title liy-common-title-v1",
                            },
                            [
                              _c("div", { staticClass: "lct-left" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lct-right" }, [
                                _c("div", { staticClass: "lct-right-text1" }, [
                                  _vm._v("文化产品"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "250px" },
                                attrs: { option: _vm.configData.chartOption6 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-right-center " },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "liy-common-title liy-common-title-v1",
                            },
                            [
                              _c("div", { staticClass: "lct-left" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lct-right" }, [
                                _c("div", { staticClass: "lct-right-text1" }, [
                                  _vm._v("农业产品加工产业产值"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "250px" },
                                attrs: { option: _vm.configData.chartOption7 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "lc-right-bottom" },
                  [
                    _c(
                      "dv-border-box-8",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          color: _vm.configData.borderBorder,
                          reverse: true,
                        },
                      },
                      [
                        _c("div", { staticClass: "lct-warp-v1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "liy-common-title liy-common-title-v1",
                            },
                            [
                              _c("div", { staticClass: "lct-left" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "lct-right" }, [
                                _c("div", { staticClass: "lct-right-text1" }, [
                                  _vm._v("工业产业"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "liy-chart1" },
                            [
                              _c("dv-charts", {
                                staticStyle: { height: "200px" },
                                attrs: { option: _vm.configData.chartOption8 },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("com-footer", { attrs: { menuIndex: 6 } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }